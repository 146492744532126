// Mixins
// -----------------------------------------------------------------------------
@mixin make-container() {
  @include container-fixed();
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
  
  @media (max-width: $screen-md - 1) {
    padding-left: 10px;
    padding-right: 10px;
  }

}