form#webform-client-form-202{
  font-family: Arial;
  font-size: 14px;
  color: #777;

  > div{
    @include clearfix();
  }

  .subscribe-txt-block{
    @include clearfix();
    margin-bottom: 48px;
    a{
      color: #1ba39c;
    }
  }

  .calculate-subscribe-block{
    clear: both;
    float: left;
    width: 378px;
    height: 87px;
    display: flex;
    flex-direction:column;
    justify-content:center;
    background-color: #828282;
    padding: 0 0 0 70px;
    font-size: 13px;
    font-weight: 900;
    color: #ffffff;
    .csb-price{
      .csb-price-value{

      }
    }

    .csb-nds{
      .csb-nds-value{

      }
    }

    .csb-total{
      color: #f9a533;
      .csb-total-value{

      }
    }

  }

  .liqpay{
    float: left;
    display: flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width: 325px;
    height: 46px;
    background-color: white;
    border: 1px solid #7ab72b;
    padding-left: 30px;
    background: url('../images/liqpay.jpg') center right 30px no-repeat;
    margin: 21px 0 0 20px;
    a{
      color: #7cb82e;
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  .download-requisites{
    float: right;
    margin: 36px 0 0 0px;
    a{
      color: #7ab72b;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .form-30{
    width: 33.33333333%;
    select,
    input[type="text"]{
      width:87.78%;
    }
  }

  .form-66{
    width: 66.6666666%;
    select,
    input[type="text"]{
      width:97%;
    }
  }

  .form-50{
    width: 50%;
    select,
    input[type="text"]{
      width:93.89%;
    }
  }

  .form-100{
    width: 100%;
  }

  div.webform-component--nazvanie-organizacii + .form-hydrid-30-50{
    width: 33.33333333%;
    select,
    input[type="text"]{
      width:87.78%;
      float: right;
    }
  }

  div.webform-component--nazvanie-organizacii.webform-conditional-hidden + .form-hydrid-30-50{
    width: 50%;
    select,
    input[type="text"]{
      float: left;
      width:93.89%;
    }
  }

  div.form-item.webform-component{
    float: left;
    margin-bottom: 30px;
  }

  div.form-item.webform-component--region-podpiski,
  div.form-item.webform-component--adres-dostavki,
  div.form-item.webform-component--elektronnaya-pochta,
  div.form-item.webform-component--rekvizity-organizacii{
    select,
    input[type="text"]{
      float: right;
    }
  }

  div.form-item.webform-component--vybrat-kolichestvo-nomerov{
    select,
    input[type="text"]{
      margin-left: auto;
      margin-right: auto;
    }
  }

  //Формстайлер селекты
  .webform-component--vybrat-kolichestvo-nomerov{
    text-align: center;
  }
  .webform-component--region-podpiski{
    text-align: right;
  }

  ::-webkit-input-placeholder {color: #777;font-weight: 400;font-size: 14px;}
  ::-moz-placeholder          {color: #777;font-weight: 400;font-size: 14px;}/* Firefox 19+ */
  :-moz-placeholder           {color: #777;font-weight: 400;font-size: 14px;}/* Firefox 18- */
  :-ms-input-placeholder      {color: #777;font-weight: 400;font-size: 14px;}

  select,
  input[type="text"]{
    height: 51px;
    background-color: #f2f2f2;
    font-weight: 400;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding-left: 31px;
  }

  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none!important;
    background-image: url('../images/select-burger.png');
    background-position: right 30px center;
    background-repeat: no-repeat;
    &::-ms-expand {
      display: none;
    }
  }

  .form-actions{
    float: left;
    margin: 22px 0 0 134px;
    input[type="submit"]{
      width: 164px;
      height: 46px;
      background-color: #1ba39c;
      color: white;
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
      border: 0;
      border-radius: 0;
      &:focus{
        outline: 0;
      }
    }
  }

}

//max-width: 1199px
@media (max-width: $screen-md-max) {

}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  form#webform-client-form-202 {
    .form-30 {
      width: 100%;
    }
    .form-control.jq-selectbox {
      width: 100%;
      ul {
        width: 100%;
      }
    }
    .calculate-subscribe-block {
      width: 100%;
    }
    .form-actions {
      margin-left: 0;
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  form#webform-client-form-202 {
    .form-item {
      padding: 0 !important;
      width: 100% !important;
    }
    input[type=text] {
      float: left;
      width: 100% !important;
    }
    .form-control.jq-selectbox {
      width: 100%;
      ul {
        width: 100%;
      }
    }
    .calculate-subscribe-block {
      width: 100%;
    }
    .form-actions {
      margin-left: 0;
      text-align: center;
      width: 100%;
      input[type=submit] {
        display: inline-block;
      }
    }
    .liqpay {
      margin-left: 0;
      width: 100%;
    }
    .download-requisites {
      text-align: center;
      width: 100%;
    }
  }
}




