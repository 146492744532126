body.node-type-about-journal {
  .paragraphs-item-p-title-text {
    .field-name-field-p-tt-title {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 84px;
      color: #828282;
      margin-top: 87px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
      margin-bottom: 26px;
    }
    .field-name-field-p-tt-text{
      color: #777;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      p {
        margin-bottom: 23px;
      }
      .about_wrap {
        color: #777;
        font-family: Georgia;
        font-size: 15px;
        line-height: 28px;
        .left_img {
          float: left;
          margin-right: 58px;
          padding-top: 8px;
        }
        .right_part {
          .signature {
            text-align: right;
              /*padding-top: 12px;*/
              color: #777;
            font-family: Arial;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            span {
              position: relative;
                top: 11px;
            }
            img {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
  .paragraphs-item-about-journal {
    .field-name-field-p-tt-title {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 84px;
      color: #828282;
      margin-top: 87px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
      margin-bottom: 26px;
    }
    .field-name-field-p-tt-text{
      color: #777;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      p {
        margin-bottom: 23px;
      }
      .about_wrap {
        color: #777;
        font-family: Georgia;
        font-size: 15px;
        line-height: 28px;
        .left_img {
          float: left;
          margin-right: 58px;
          padding-top: 8px;
        }
        .right_part {
          .signature {
            text-align: right;
              /*padding-top: 12px;*/
              color: #777;
            font-family: Arial;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            span {
              position: relative;
                top: 11px;
            }
            img {
              margin-left: 24px;
            }
          }
        }
      }
    }
    .field-name-field-p-tti-image {
      float: left;
      margin-right: 58px;
      padding-top: 8px; 
    }
  }
  .paragraphs-item-p-insert-block {
    padding-top: 60px;
  }
  .paragraphs-item-p-insert-block {
    .block-views {
      .block__title {
        font-family: Arial;
        background: url('../images/rz_title.png') left top no-repeat;
        min-height: 73px;
        padding-left: 92px;
        color: #828282;
        margin-top: 87px;
        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 49px;
        margin-bottom: 26px;
      }
      .journal_teaser {
        max-width: 212px;
        position: relative;
        text-align: center;
        margin: 0 auto;
        .centered {
          position: absolute;
            top: 50%;
            width: 100%;
            margin-top: -13px;
        }
        a {
          color: white;
            font-size: 10px;
            font-weight: 900;
            line-height: 27px;
            text-transform: uppercase;
            background: #ca1e24;
            display: inline-block;
            padding: 0px 20px;
            letter-spacing: 1px;
            text-decoration: none;
            display: none;
        }
        &:hover {
          img {
            opacity: 0.4;
          }
          a {
            display: inline-block;
          }
        }
      }
      .slick__arrow {
        padding-top: 37px;
      }
    }

  }
  .reviews.panels-flexible-row {
    background: #f2f2f2;
    padding-top: 69px;
    margin-top: 72px;
    padding-bottom: 60px;
    padding-bottom: 150px;
    .pane-title{
      color: #828282;
      font-family: Arial;
      font-size: 45px;
      font-weight: 900;
      line-height: 45px;
      text-transform: uppercase;
      padding-bottom: 39px;
    }
    .slick__slide.slide{
      .slide__content {
        background: url('../images/rz_title.png') left top 8px no-repeat;
        padding-left: 123px;
        .views-field-title {
          font-family: Arial;
          font-size: 14px;
          color: #ca1e24;
          font-weight: 900;
          line-height: 24px;
        }
        .views-field-body {
          font-family: Arial;
          font-size: 14px;
          color: #555;
          font-weight: 400;
          line-height: 24px;
          padding-top: 17px;
        }
      }
    }
    .slick-track {
      & > .slick-slide {
        & > div {
          padding-bottom: 38px;
        }
      }
    }
    .view-review-readers {
      position: relative;
      .slick__arrow {
        position: absolute;
        top: -85px;
        right: 0px;
        button {
            box-shadow: none;
            margin-left: 3px;
            margin-right: 0px;
            outline: none;
        }
      }
    }
  }
  .prefooter {
    display: none;
  }
  .reviews_add {
    padding-left: 124px;
    a {
      color: white;
      font-family: Arial;
      font-size: 12px;
      font-weight: 900;
      line-height: 20px;
      text-transform: uppercase;
      background: #1ba39c;
      line-height: 46px;
      display: inline-block;
      padding: 0px 54px;
      outline: none;
    }
  }
}

.statistics_counter {
  display: none;
}

#block-webform-client-block-202{
  h4.block__title{
    font-family: Arial;
    background: url('../images/rz_title.png') left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    margin-top: 87px;
    font-size: 31px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 65px;
    margin-bottom: 26px;
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.node-type-about-journal {
    .paragraphs-item-p-insert-block {
      .block__content {
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.node-type-about-journal {
    .paragraphs-item-p-title-text {
      .field-name-field-p-tt-title {
        font-size: 30px;
        line-height: 63px;
        background: none;
        text-align: center;
        padding-left: 0px;
        .field-items {
          display: inline-block;
          line-height: normal;
          padding-left: 84px;
          line-height: 63px;
          background: url('../images/rz_title.png') left top no-repeat;
          min-height: 73px;
        }
      }
    }
    #block-webform-client-block-202{
      text-align: center;
      h4.block__title{
        font-size: 30px;
        line-height: 63px;
        display: inline-block;
      }
      .block__content {
        text-align: left;
      }
    }
    .paragraphs-item-p-insert-block {
      .block-views {
        text-align: center;
        .block__title {
          font-size: 30px;
          line-height: 63px;
          display: inline-block;
        }
      }
      .block__content {
        text-align: left;
      }
    }
    .reviews.panels-flexible-row {
      .pane-title {
        font-size: 30px;
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.node-type-about-journal {
    .paragraphs-item-p-title-text {
      .field-name-field-p-tt-title {
        background-position: center top;
        padding-left: 0;
        padding-top: 80px;
        text-align: center;
        .field-items {
          display: inline-block;
          font-size: 21px;
          line-height: normal;
        }
      }
      .field-name-field-p-tt-text {
        text-align: center;
        .about_wrap {
          .left_img {
            float: none;
            margin-bottom: 20px;
            text-align: center;
            width: 100%;
            img {
              display: inline-block;
              height: auto;
              max-width: 100%;
            }
          }
          .right_part {
            text-align: center;
          }
        }
      }
    }
    .paragraphs-item-p-insert-block {
      position: relative;
      .block-views {
        text-align: center;
        .block__title {
          background-position: center top;
          display: inline-block;
          font-size: 21px;
          line-height: normal;
          padding-left: 0;
          padding-top: 80px;
          margin-top: 0px;
        }
      }
    }
    .reviews.panels-flexible-row {
      padding-top: 30px;
      padding-bottom: 60px;
      .pane-title {
        font-size: 21px;
        padding-bottom: 10px;
        text-align: center;
      }
      .view-review-readers {
        .slick-slider {
          padding-top: 60px;
          .slide__content {
            background-position: center top;
            padding-left: 0;
            padding-top: 80px;
          }
          .views-field-title {
            float: none;
            text-align: center;
            width: 100%;
          }
          .views-field-body {
            margin-top: 20px;
            text-align: center;
          }
        }
        .slick__arrow {
          top: 0;
          text-align: center;
          width: 100%;
        }
      }
    }
    .reviews_add {
      padding-left: 0;
      width: 100%;
      .pane-content {
        text-align: center;
      }
    }
  }
  #block-webform-client-block-202 {
    text-align: center;
    h4.block__title {
      background-position: center top;
      display: inline-block;
      font-size: 21px;
      line-height: normal;
      padding-left: 0;
      padding-top: 80px;
    }
    .block__content {
      text-align: left;
    }
  }
}


