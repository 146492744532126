body.node-type-contacts {
	.page-header {
		border: 0px;
	}
	.title {
	    background: url(../images/rz_title.png) left top no-repeat;
	    min-height: 73px;
	    padding-left: 92px;
	    color: #828282;
	    margin-bottom: 18px;
	    font-size: 45px;
	    font-weight: 900;
	    text-transform: uppercase;
	    line-height: 49px;
	    margin-top: 87px;
	    padding-left: 83px;
	    margin-bottom: 11px;
	}
	.pane-node-field-text-top {
		padding: 0px 11px;
	}

	.city {
		font-family: Arial;
		font-size: 14px;
		color: #1ba39c;
		font-weight: 700;
		line-height: 22px;
	}
	.office {
		font-family: Arial;
		font-size: 14px;
		color: #777;
		font-weight: 700;
		line-height: 24px;
	}
	.office_person {
		font-family: Arial;
		font-size: 14px;
		color: #777;
		font-weight: 700;
		line-height: 24px;
    	padding-top: 7px;
	}
	.office_phone {
		font-family: Arial;
		font-size: 14px;
		color: #777;
		font-weight: 400;
		line-height: 21px;
	}
	.office_mail {
		font-family: Arial;
		font-size: 14px;
		color: #ca1e24;
		font-weight: 400;
		line-height: 22px;
		text-decoration: underline;
	}
	.field-name-field-contact-points {
		display: none;
	}

	.node-contacts {
		padding: 0px 11px;
    	padding-top: 29px;
    	padding-bottom: 40px;
    	.content {
    		display: flex;
    		width: 100%;	
    		@media (max-width: 767px) {
				display: block;
			}
    		.field-name-field-left-contact {
    			width: 302px;
    			border-right: 1px solid #bae3e1;
    			@media (max-width: 767px) {
    				width: 100%;
    				border-right: 0px solid #bae3e1;
    				border-bottom: 1px solid #bae3e1;
    				padding-bottom: 30px;
    				margin-bottom: 20px;
    			}
    			.city {
    				padding-top: 6px;
    			}
    			.office {
    				padding-top: 21px;
    			}
    		}
    		.paragraphs-items-field-additional-contacts {
    			width: calc(100% - 302px)!important;
    			padding-top: 6px;
    			@media (max-width: 767px) {
    				width: 100%!important; 
    			}
    			.field-name-field-additional-contacts {
	    			& > .field-items {
	    				display: flex;
	    				flex-wrap: wrap;
					    justify-content: space-between;
					    padding-left: 42px;
					    padding-right: 11px;
					    @media (max-width: 767px) {
							justify-content: space-around;
						}
					    .field-item {
					    	min-width: 165px;
					    	padding-bottom: 23px;
					    }
	    			}
    			}
    		}
    	}
	}
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.node-type-contacts{
    .page-header {
      text-align: center;
    }
    .title {
      display: inline-block;
      font-size: 30px;
      line-height: 63px;
    }
  }
}


//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.node-type-contacts {
    .page-header {
      text-align: center;
      .title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
  }
}