body.page-editorial-council {
  #page-header {
    padding-top: 86px;
    padding-bottom: 12px;
    .page-header {
      margin: 0px;
      padding: 0px;
      border: 0px;
    }
  }
  .title {
    background: url('../images/rz_title.png') left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    margin-bottom: 18px;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 49px;
  }
  .view-editorial-council {
    padding-top:10px;
    .views-row {
      width: 50%;
      float: left;
      padding-right: 36px;
      padding-bottom: 20px;
      min-height: 198px;
      &:nth-child(2n) {
        padding-left: 36px;
        padding-right: 0px;
      }
      &:nth-child(2n+1) {
        clear: both;
      }
      .image {
        float: left;
      }
      .content_article {
        color: #777;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding-left: 148px;
          top: -4px;
          position: relative;
          .title_article2 {
            padding-bottom: 9px;
          }
      }
      .title_article,.title_article2 {
        color: #1ba39c;
        font-size: 19px;
        font-weight: 900;
        line-height: 22px;
      }
    }
    .all_journal_b {
      clear: both;
      padding-top:26px;
      padding-bottom: 79px;
    }
  }
  .horizontal_baner {
    padding-top:0px;
  }
  .editorial_council_contact {
    font-size: 21px;
    font-weight: 700;
    line-height: 35px;
    color: #777;
    padding-top: 71px;
    word-spacing: 1px;
    p {
      margin-bottom: 0px;
    }
    a {
      text-decoration: none;
      color: #777;
    }
  }
  .editorial_council_form {
    padding-top: 65px;
    .alert {
      display: none;
    }
    .pane-title {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 84px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 31px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 64px;
    }
    input[type="text"],input[type="email"] {
      border:0px;
      background:#f2f2f2;
      border-radius: 0px;
      box-shadow:none;
      line-height: 50px;
      height: 50px;
      padding-left:30px;
      &.error {
        border: 1px solid red;
      }
    }
    .col-xl-6 {
      width: 50%;
      float: left;
      padding-right:36px;
      &:nth-child(2) {
        padding-right:0px;
        padding-left:36px;
      }
    }
    .webform-client-form-143 {
      padding-top:15px;
    }
    textarea {
      background:#f2f2f2;
      width: 100%;
      height: 121px;
      border:0px;
      border-radius:0px;
      resize: none;
      box-shadow: none;
      padding-left: 29px;
        padding-top: 15px;
    }
    .webform-component--kommentariy {
      clear: both;
      padding-top:17px;
    }
    .form-actions {
      text-align: center;
      padding-top: 24px;
      input {
        color: white;
        font-size: 12px;
        font-weight: 900;
        line-height: 20px;
        text-transform: uppercase;
        background:#1ba39c;
        border-radius:0px;
        border:0px;
        line-height: 46px;
        padding: 0px 30px;
      }
    }
  }
}


//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.page-editorial-council {
    .view-editorial-council {
      .first_part,
      .second_part {
        display: inline-block;
        width: 100%;
      }
      .all_journal_b {
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.page-editorial-council {
    .title {
      font-size: 30px;
      line-height: 63px;
    }
    .editorial_council_form {
      padding-bottom: 60px;
      .pane-title {
        font-size: 30px;
        line-height: 63px;
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.page-editorial-council {
    .page-header {
      text-align: center;
      .title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
    .view-editorial-council {
      margin-bottom: 40px;
      .views-row {
        min-height: 10px;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        &:nth-child(2n) {
          padding-left: 0;
        }
        &:nth-child(2n+1) {
          width: 100%;
        }
        .image {
          float: none;
          text-align: center;
          width: 100%;
          img {
            display: inline-block;
          }
        }
        .content_article {
          float: none;
          margin-top: 20px;
          padding-left: 0;
          text-align: center;
          top:0;
          width: 100%;
        }
      }
      .all_journal_b {
        padding-bottom: 40px;
      }
    }
    .editorial_council_contact {
      .pane-content {
        text-align: center;
      }
    }
    .editorial_council_form {
      padding-bottom: 60px;
      text-align: center;
      .pane-title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
      .pane-content {
        text-align: left;
      }
      .col-xl-6 {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        &:nth-child(2) {
          padding-left: 0;
        }
      }
      .webform-component--kommentariy {
        padding-top: 0;
      }
    }
  }
}
