.pane-collage-front{
  h2.pane-title{
    background: url(../images/rz_title.png) left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 49px;
    font-family: Arial;
  }

  .view-collage-front{
    .view-content{
      .views-row{
        .node-colage-front{
          .wrapp_collage{
            .all_wrapp{
              .show-more-btn {
                display: none;
              }
              @include clearfix();
              overflow: hidden;
              &.type_1{
                .image-other-elem-1{
                  float: right;
                  .wrapp_image{
                    float: right;
                    margin-left: 30px;
                  }
                }
                .image-other-elem-2{
                  .wrapp_image{
                    /*margin-right: 30px;*/
                  }
                }
                .wrapp_url-2{
                  padding-top: 9px;
                }
              }

              &.type_2{
                .image-other-elem-1{
                  float: right;
                  right: -60px;
                  .wrapp_image{
                    float: right;
                    /*margin-left: 30px;*/
                  }
                }
              }

              .first-two-image-elem{
                width: 100%;
                display: flex;
                flex-direction:row;
                justify-content:space-between;
              }

              .image-other-elem{
                @include clearfix();
                width: 800px;
              }

              .wrapp_url{
                width: 380px;
                padding-top: 43px;
                .wrapp_title_url{
                  font-size: 15px;
                  margin-bottom: 57px;
                  > .title{
                    font-weight: 900;
                    text-transform: uppercase;
                    > *{
                      font-weight: 900;
                      text-transform: uppercase;
                      color: #333333;
                      text-decoration: none;
                    }
                  }
                  > .wrapp_line_contets{
                    margin-top: 5px;
                    a {
                      text-decoration: none;
                    }
                   .number{
                      font-weight: 900;
                      color: #ca1e24;
                      padding-right: 3px;
                    }
                    .title{
                      padding-left: 3px;
                      color: #777777;
                    }
                  }
                }
              }

              .wrapp_url,
              .image-other-elem{
                float: left;
              }

              .wrapp_image{
                background-repeat: no-repeat;
                background-size: cover;
                float: left;
                margin-bottom: 30px;
                position: relative;
                a {
                  position:absolute;
                  width: 100%;
                  height: 100%;
                  left: 0px;
                  top: 0px;
                }
                .number{
                  font-size: 51px;
                  font-weight: 900;
                }
                .title{
                  font-size: 15px;
                  font-weight: 400;
                }

                &.front_cologae_first_image{
                  width: 850px;
                  height: 488px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .number{
                    padding: 31px 3px 35px 1px;
                    letter-spacing: 3.5px;
                    min-height: 139px;
                    width: 18%;
                    text-align: right;
                  }
                  .title{
                    padding: 48px 0px 48px 9px;
                    min-height: 139px;
                    letter-spacing: 0.4px;
                    width: 82%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  }
                }

                &.front_cologae_vertical_small{
                  width: 300px;
                  //width: 40.54%;
                  height: 488px;
                  padding-bottom: 50px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: flex-end;
                  text-align: right;
                  .number{
                    padding: 0px 38px 0 0;
                    letter-spacing: 3.5px;
                    width: 75%;
                  }
                  .title{
                    width: 75%;
                    padding: 0 44px 16px 30px;
                  }
                }

                &.front_cologae_vertical_middle{
                  width: 410px;
                  //width: 55.4%;
                  height: 488px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: flex-start;
                  padding-top: 28px;
                  .number{
                    letter-spacing: 3.5px;
                    padding: 2px 34px 0 0;
                    width: 45%;
                    text-align: right;
                  }
                  .title{
                    padding: 0px 41px 12px 31px;
                    margin-top: 0px;
                    text-align: right;
                    line-height: 1.5;
                    width: 45%;
                  }
                }

                &.front_cologae_gorizont{
                  width: 740px;
                  //width: 100%;
                  height: 488px;
                  display: -ms-flexbox;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  align-items: flex-start;
                  padding-top: 30px;
                  padding-bottom: 30px;
                  padding-left: 20px;
                  .number{
                    padding: 1px 15px 4px 18px;
                    letter-spacing: 3.5px;
                  }
                  .title{
                    margin-top: auto;
                    text-align: right;
                    padding: 14px 44px 18px 30px;
                    line-height: 1.5;
                    width: 33%;
                    margin-left: auto;
                  }
                }

                &.front_cologae_gorizont_small{
                  width: 300px;
                  //width: 40.54%;
                  height: 229px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  padding-top: 20px;
                  .number{
                    padding: 10px 38px 0 0;
                    letter-spacing: 3.5px;
                    width: 68%;
                    text-align: right;
                  }
                  .title{
                    text-align: right;
                    padding: 0 44px 12px 18px;
                    line-height: 1.5;
                    width: 68%;
                  }
                }

              }

            }
          }
        }
      }
    }
  }
}

.collage-slick {
  position: relative;
  .wrapp_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  }
  .slick-arrow {
    border-radius: 50%;
    border: none;
    font-size: 0;
    height: 50px;
    opacity: 1;
    outline: none;
    position: absolute;
    text-indent: -99999px;
    text-align: center;
    width: 50px;
    z-index: 2;
    &.slick-prev {
      background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
      left: -27px;
      transform: none;
    }
    &.slick-next {
      background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
      right: -27px;
    }
  }
}

//min-width: 1200px
@media (min-width: $screen-lg) {
  .collage-slick {
    display: none;
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  .pane-collage-front {
    .view-collage-front {
      .view-content {
        .views-row {
          .node-colage-front {
            .wrapp_collage {
              .all_wrapp {
                height: 530px;
                position: relative;
                &:after {
                  background: url(../images/all_wrapp_hide_bg.png) center no-repeat;
                  bottom: 0;
                  content: '';
                  height: 196px;
                  left: 50%;
                  position: absolute;
                  transform: translateX(-50%);
                  z-index: 2;
                  width: 320px;
                }
                &.show-more {
                  height: auto;
                  &:after {
                    display: none;
                  }
                  .show-more-btn {
                    display: none;
                  }
                }
                .image-other-elem,
                .first-two-image-elem {
                  display: none;
                }
                .wrapp_url {
                  float: none;
                  text-align: center;
                  width: 100%;
                }
                .show-more-btn {
                  background-color: #ca1e24;
                  bottom: 0;
                  color: #fff;
                  cursor: pointer;
                  display: block;
                  font-size: 12px;
                  font-weight: 400;
                  height: 46px;
                  left: 50%;
                  line-height: 46px;
                  position: absolute;
                  text-align: center;
                  text-transform: uppercase;
                  transform: translateX(-50%);
                  width: 236px;
                  z-index: 3;
                }
              }
            }
          }
        }
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  .collage-slick {
    .wrapp_image {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      height: 431px;
      justify-content: flex-start;
      width: 100%;
      .number {
        font-size: 51px;
        font-weight: 900;
        letter-spacing: 3.5px;
        min-height: 138px;
        padding: 31px 3px 35px 1px;
        text-align: right;
        width: 18%;
      }
      .title {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 400;
        flex-direction: row;
        letter-spacing: 0.4px;
        min-height: 138px;
        padding: 48px 0px 48px 9px;
        width: 82%;
      }
    }
    .slick-arrow {
      top: 260px;
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  .collage-slick {
    .wrapp_image {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: 350px;
      justify-content: flex-start;
      width: 100%;
      .number {
        font-size: 51px;
        font-weight: 900;
        letter-spacing: 3.5px;
        height: 100px;
        padding: 10px 3px 10px 1px;
        text-align: center;
        width: 100px;
      }
      .title {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 400;
        flex-direction: row;
        letter-spacing: 0.4px;
        height: 100px;
        padding: 48px 0px 48px 9px;
        width: calc(100% - 100px);
      }
    }
    .slick-arrow {
      top: 100px;
    }
  }
}







