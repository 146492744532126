body.page-all-journal {
  .view-old-journal {
    position: relative;
  }
  .page-header {
    margin-bottom: 0px;
      padding-bottom: 11px;
      border: 0px;
  }
  .title {
    background: url('../images/rz_title.png') left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    margin-bottom: 18px;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 49px;
    margin-top: 87px;
      padding-left: 83px;
  }
  .view-filters {
    position: absolute;
    right: 0px;
    top: -102px;
    width: 300px;
    .form-item-field-artical-print-subjects-tid {
      width: 100%;
    }
    .views-exposed-form .views-exposed-widget {
      float: none;
    }
    select {
      width: 100%;
    }
  }
  .view-content {
    .first_part, .second_part {
      .views-row {
        text-align: center;
        max-width: 212px;
        float: left;
        margin-right: 30px;
        &:nth-child(5n) {
          margin-right: 0px;
        }
        &:nth-child(5n+1) {
          clear: both;
        }
        .views-field-title {
          padding-top: 15px;
          padding-bottom: 55px;
          a {
            color: #828282;
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
      }
    }
    .journal_teaser {
      max-width: 212px;
      position: relative;
      text-align: center;
      .centered {
        position: absolute;
          top: 50%;
          width: 100%;
          margin-top: -13px;
      }
      a {
        color: white;
          font-size: 10px;
          font-weight: 900;
          line-height: 27px;
          text-transform: uppercase;
          background: #ca1e24;
          display: inline-block;
          padding: 0px 20px;
          letter-spacing: 1px;
          text-decoration: none;
          display: none;
      }
      &:hover {
        img {
          opacity: 0.4;
        }
        a {
          display: inline-block;
        }
      }
    }
    .second_part {
      clear: both;
    }
  }
  .all_journal_b {
    padding-bottom: 50px;
  }
  .item-list {
    clear: both;
    padding-top: 18px;
    ul {
      margin: 0px;
      margin-top: -20px;
    }
    li {
      a {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        border: 0px;
        box-shadow:none;
        margin-right: 3px;
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }

      &.pager-previous {
        a {
          background: url('../images/slide_arroy_active.jpg');
          -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }
      }
      &.pager-next {
        a {
          background: url('../images/slide_arroy_active.jpg');
        }
      }
      &.pager-current {
        display: none;
      }
    }
  }
  .horizontal_baner {
    padding-top: 55px;
  }
  .popular_block {
    .pane-title {
      min-height: 73px;
      padding-top: 51px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
    }
    .view-popular-block {
      padding-top: 39px;
      .view-content {
        display: flex;
        justify-content: space-between;
      }
      .slick {
        width: 100%;
      }
      .views-row,
      .slick-slide {
        width: 265px;
        .popular_img_wrap {
          position: relative;
          .count_views {
            position: absolute;
            bottom: 20px;
            background: rgba(27,163,156,0.9);
            color: white;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            padding-left: 14px;
            .count {
              text-align: center;
              display: inline-block;
              width: 56px;
              position: relative;
              top: 1px;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 13px;
              height: 10px;
              background: url('../images/popular_views.png');
              position: relative;
              top: 1px;
            }
          }
        }
        .views-field-title {
          padding-top: 26px;
          a {
            color: #333;
            font-size: 13px;
            font-weight: 900;
            line-height: 17px;
            text-decoration: none;
          }
        }

      }
    }
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.page-all-journal {
    .popular_block {
      .slide__content {
        margin: 0 auto;
        width: 265px;
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.page-all-journal {
    .title {
      font-size: 30px;
      line-height: 63px;
    }
    .view-id-old_journal {
      .view-filters {
        top: -84px;
      }
      .view-content {
        .second_part,
        .first_part {
          .views-row {
            margin-right: 55px;
            &:nth-child(5n+1) {
              clear: none;
              margin-right: 55px;
            }
            &:nth-child(3n+1) {
              clear: both;
              margin-right: 55px;
            }
            &:nth-child(3n+3) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .popular_block {
      padding-bottom: 60px;
      padding-top: 60px;
      position: relative;
      .pane-title {
        border-top: 0;
        font-size: 31px;
        font-weight: 900;
        margin-top: 0;
        padding-top: 0;
        text-align: center;
      }
      .view-popular-block {
        padding-top: 0;
        position: relative;
        .popular_img_wrap {
          img {
            height: auto;
            width: 100%;
          }
        }
        .slick-slider {
          margin: 0 auto;
          width: 654px;
        }
        .slick-slide {
          padding: 0 20px;
        }
        .slick-arrow {
          border-radius: 50%;
          height: 50px;
          opacity: 1;
          outline: none;
          position: absolute;
          text-align: center;
          top: 40%;
          width: 50px;
          z-index: 2;
          &.slick-prev {
            background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
            left: -27px;
            transform: translateY(-50%);
          }
          &.slick-next {
            background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
            right: -27px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.page-all-journal {
    .page-header {
      text-align: center;
      .title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
    .view-id-old_journal {
      .view-filters {
        margin-bottom: 30px;
        position: initial;
        text-align: center;
        width: 100%;
      }
      .view-content {
        .first_part,
        .second_part {
          display: inline-block;
          text-align: center;
          width: 100%;
          .views-row {
            display: inline-block;
            float: none;
            margin-right: 0;
            &:nth-child(5n+1) {
              clear: none;
              margin-right: 0;
            }
          }
        }
      }
    }
    .horizontal_baner {
      display: none;
      padding-top: 40px;
    }
    .popular_block {
      padding-bottom: 60px;
      padding-top: 20px;
      position: relative;
      .pane-title {
        border-top: 4px solid #1ba39c;
        color: #1ba39c;
        font-family: "Arial Black";
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 30px;
        text-align: center;
      }
      .view-popular-block {
        position: relative;
        .slick-track {
          width: 100% !important;
        }
        .slick-slide {
          float: none;
          width: 100% !important;
        }
        .slick__arrow {
          display: none;
        }
      }
    }
  }
}

