#modalContent {
	width: 100%!important;
	left: 0px!important;
	.silver-modal-dialog {
		width: 100%!important;
	}
	.modal-content {
		border-radius: 0px!important;
		.silver-modal-header {
			@include make-container();
			border-bottom: 0px;
			position: relative;
			padding-bottom: 13px;
			.ctools-close-modal {
				background: url('../images/modal_popup_close.png');
				width: 28px;
				height: 28px;
				text-indent: -9999px;
				opacity: 1;
				margin-top: 62px;
				outline: none;
			}
			#modal-title {
				font-family: Arial;
				background: url('../images/rz_title.png') left top no-repeat;
				min-height: 73px;
				padding-left: 84px;
				color: #828282;
				margin-top: 45px;
				font-size: 31px;
				font-weight: 900;
				text-transform: uppercase;
				line-height: 62px;
				
			}
		}
		.silver-modal-body {
			& >div,& >form {
				@include make-container();
			}
			.alert {
				display: none;
			}
			.send-class {
				label {
					display: none;
				}
				input[type="text"],input[type="email"] {
					border:0px;
					background:#f2f2f2;
					border-radius: 0px;
					box-shadow:none; 
					line-height: 50px;
					height: 50px;
					padding-left:30px;
					&.error {
						border: 1px solid red;
					}
				}		
				input[type="submit"] {
					text-align: center;
					margin: 0 auto;
					margin-top: 24px;
					color: white;
					font-size: 12px;
					font-weight: 900;
					line-height: 20px;
					text-transform: uppercase;
					background:#1ba39c;
					border-radius:0px;
					border:0px;
					line-height: 46px;
					padding: 0px 30px;
					display: block;

				}	
			}
			.webform-client-form-143 {
				.alert {
					display: none;
				}
				.pane-title {
					background: url('../images/rz_title.png') left top no-repeat;
					min-height: 73px;
					padding-left: 84px; 
					color: #828282;
					margin-bottom: 18px;
					font-size: 31px;
					font-weight: 900;
					text-transform: uppercase;
					line-height: 64px;
				}
				input[type="text"],input[type="email"] {
					border:0px;
					background:#f2f2f2;
					border-radius: 0px;
					box-shadow:none; 
					line-height: 50px;
					height: 50px;
					padding-left:30px;
					&.error {
						border: 1px solid red;
					}
				}
				.col-xl-6 {
					width: 50%;
					float: left;
					padding-right:36px;
					&:nth-child(2) {
						padding-right:0px;
						padding-left:36px;
					}
				}
				.webform-client-form-143 {
					padding-top:15px;
				}
				textarea {
					background:#f2f2f2;
					width: 100%;
					height: 121px;
					border:0px;
					border-radius:0px;
					resize: none;
					box-shadow: none;
					padding-left: 29px;
		    		padding-top: 15px;
		    		&.error {
						border: 1px solid red;
					}
				}
				.webform-component--kommentariy {
					clear: both;
					padding-top:17px;
				}
				.form-actions {
					text-align: center;
					padding-top: 24px;
					input {
						color: white;
						font-size: 12px;
						font-weight: 900;
						line-height: 20px;
						text-transform: uppercase;
						background:#1ba39c;
						border-radius:0px;
						border:0px;
						line-height: 46px;
						padding: 0px 30px;
					}
				}
			}
			.webform-client-form-201 {
				.alert {
					display: none;
				}
				.pane-title {
					background: url('../images/rz_title.png') left top no-repeat;
					min-height: 73px;
					padding-left: 84px; 
					color: #828282;
					margin-bottom: 18px;
					font-size: 31px;
					font-weight: 900;
					text-transform: uppercase;
					line-height: 64px;
				}
				input[type="text"],input[type="email"] {
					border:0px;
					background:#f2f2f2;
					border-radius: 0px;
					box-shadow:none; 
					line-height: 50px;
					height: 50px;
					padding-left:30px;
					&.error {
						border: 1px solid red;
					}
				}
				.col-xl-6 {
					width: 50%;
					float: left;
					padding-right:36px;
					&:nth-child(2) {
						padding-right:0px;
						padding-left:36px;
					}
				}
				.webform-client-form-143 {
					padding-top:15px;
				}
				textarea {
					background:#f2f2f2;
					width: 100%;
					height: 121px;
					border:0px;
					border-radius:0px;
					resize: none;
					box-shadow: none;
					padding-left: 29px;
		    		padding-top: 15px;
		    		&.error {
						border: 1px solid red;
					}
				}
				.webform-component--kommentariy {
					clear: both;
					padding-top:17px;
				}
				.form-actions {
					text-align: center;
					padding-top: 24px;
					input {
						color: white;
						font-size: 12px;
						font-weight: 900;
						line-height: 20px;
						text-transform: uppercase;
						background:#1ba39c;
						border-radius:0px;
						border:0px;
						line-height: 46px;
						padding: 0px 30px;
					}
				}
			}
		}
	}
}