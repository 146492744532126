// Page Layout
// -----------------------------------------------------------------------------
#page-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}
body {
  font-family: Arial;
  .left_menu {
    position: absolute;
    background: #157a75;
    top: 144px;
    height: calc(100% - 357px);
    transition: all 0.2s ease-in-out;
    z-index: 50;
    &.bottom {
      .region-right-menu {
        position: absolute;
        bottom: 0px;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      padding-top: 31px;
      padding-bottom: 31px;
      li {
        &:hover {
          background: #1ba39c;
        }
        a {
          color: white;
          font-family: Arial;
          font-size: 13px;
          font-weight: 400;
          line-height: 13px;
          text-transform: uppercase;
          background: none!important;
          padding: 9px 0px;
          padding-left: 60px;
        }
      }
    }
  }
  &.open_menu {
    .left_menu {
      right: 0px!important;

    }
    .menu_button {
      background: #157a75!important;
    }
  }
  #main-wrapper {
    position: relative;
    overflow: hidden;
  }

}
.container .container, .container .container-fluid {
  margin: 0px;
}

.slick__arrow {
  button {
    text-indent: -9999px;
    width: 32px;
    height: 32px;
    border: 0px;
    box-shadow:none;
    margin-right: 3px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .slick-prev {
    background: url('../images/slide_arroy_active.jpg');
    -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
  }
  .slick-next {
    background: url('../images/slide_arroy_active.jpg');
  }
}

.horizontal_baner {
  padding-top:90px;
  .pane-content {
    overflow: hidden;
  }
}

.old_journal {
  .rz_title  {
    .pane-title {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 92px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
      margin-top: 87px;
        padding-left: 83px;
    }
  }
  .view-old-journal {
    padding-top: 6px;
    position: relative;
    .slick__slider {
      overflow: hidden;
      padding-bottom: 40px;
    }
    .slick-list {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
    .journal_teaser {
      margin: 0px auto;
      max-width: 202px;
      position: relative;
      text-align: center;
      .centered {
        position: absolute;
          top: 50%;
          width: 100%;
          margin-top: -13px;
      }
      a {
        color: white;
          font-size: 10px;
          font-weight: 900;
          line-height: 27px;
          text-transform: uppercase;
          background: #ca1e24;
          display: inline-block;
          padding: 0px 20px;
          letter-spacing: 1px;
          text-decoration: none;
          display: none;
      }
      &:hover {
        img {
          opacity: 0.4;
        }
        a {
          display: inline-block;
        }
      }
      img {
        width: 100%;
        height: auto;
      }

    }
    
    .views-field-title {
      padding-top: 15px;
      padding-bottom: 55px;
      text-align: center;
      a {
        color: #828282;
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
        text-decoration: none;
      }
    } 
  }
}

.prefooter {
  background: #f2f2f2;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-top: 80px;
  .widget_wrap {
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    &>div {
      max-width: 366px;
      padding-bottom: 5px;
    }
    .widget3_wrap {
      background: white;
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 160px;
      padding: 0px 21px;
      position: relative;
      &:before {
        content: '';
        width: 1px;
        background: red;
        position: absolute;
        top: 10%;
        height: 80%;
        left: 5px;
      } 
      &:after {
        content: '';
        width: 1px;
        background: red;
        position: absolute;
        top: 10%;
        height: 80%;
        right: 5px;
      }
    }
  }
}
#footer {
  background: #1ba39c;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 34px;
  .region-social-top {
    width: 137px;
    .block__content {
      ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
          display: block;
            float: left;
            padding: 0px;
            width: 34px;
            height: 34px;
          a {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-bottom: 2px;
            margin-left: 2px;
            &.vk {
              background: url('../images/vk.png');
              &:hover {
                background: url('../images/vk_active.jpg');
              }
            }
            &.fb {
              background: url('../images/fb.png');
              &:hover {
                background: url('../images/fb_active.jpg');
              }
            }
            &.gp {
              background: url('../images/gp.png');
              &:hover {
                background: url('../images/gp_active.jpg');
              }
            }
            &.yt {
              background: url('../images/yt.png');
              &:hover {
                background: url('../images/yt_active.jpg');
              }
            }
          }
        }
      }
    }
  }
  .bottom_part_wrap {
    clear: both;
    position: relative;
    text-align: center;
    .menu_button {
        padding: 8px;
        padding-bottom: 8px;
        display: inline-block;
        margin-left: 25px;
        padding-top: 35px;
      span {
        width: 29px;
        height: 4px;
        background: white;
        margin: 4px 0px;
        display: block;
      }
    }
    #main-menu {
      margin: 0px;
      padding: 0px;
      display: inline-block;
      float: none;
      padding-top: 36px;
      position: relative;
      top: -7px;
      li {
        display: inline-block;
        background: none;
        padding: 5px 30px;
        word-spacing: 1px;
        &.last  {
          padding-right: 0px;
        }
        a {
          color: white;

          font-size: 13px;
          /*font-weight: 900;*/
          line-height: 18px;
          text-transform: uppercase;
          padding: 0px;
          background: none;
        }
      }
    }
  }
  .region-menu-bottom {
    width: 504px;
    margin: 0 auto;
    padding-top: 7px;
    .block__content {
      ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
          display: inline-block;
          float: left;
          a {
            width: 250px;
            height: 32px;
            background: #157a75;
            color: #7ed0cc;

            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            text-transform: uppercase;
            text-align: center;
            line-height: 32px;
            margin-left: 2px;
            margin-bottom: 2px;
            letter-spacing: 1px;
            padding: 0px;
            &:hover,&.active {
              background: #05605c;
              color: white;
              padding: 0px;
            }
          }
        }
      }
    }
  }
  .copirate_develop {
    clear: both;
    display: flex;
    justify-content: center;
    padding-top: 28px;
    .copirate {
      color: white;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      padding-top: 4px;
      padding-left: 11px;
    }
    .developer {
      color: white;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      padding-right: 10px;
      padding-top: 4px;
      text-align: right;
      a {
        text-decoration: underline;
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .copirate,.developer {
      width: 184px;
    }
  }
}

.scrollToTop {
  width: 50px;
  height: 50px;
  display: block;
  background: url('../images/scroll_top.png');
  position: fixed;
    bottom: 10px;
    right: 50px;
}


.front_article_wrap {
  float: left;
  max-width: 850px;
  width: calc(100% - 300px);
}
.front_baner_right {
  width: 300px;
  padding-top: 31px;
  float: right;
}
.front_article_wrap,.front-article-wrap {
  .slick-slide {
    margin-right: 35px;
  }
  article {
    .img_title {
      position: relative;
          max-width: 345px;
          margin: 0 auto;
      img {
        display: block;
        height: auto;
        max-width: 100%;
      }
      &:after {
        background: url('../images/front_article_img_hover.png') bottom center no-repeat;
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1;
          content: '';
          bottom: 0px;
      }
      .name {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        position: absolute;
          bottom: 0px;
          padding: 0px 32px;
          word-spacing: 1px;
          z-index: 2;
          width: 100%;
      }
      .name_front a {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        position: absolute;
          bottom: 0px;
          padding: 0px 32px;
          word-spacing: 1px;
          z-index: 2;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          text-decoration: none;
      }
      .field-name-field-image-square {
        position: relative;
        &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            content: '';
            background: rgba(0,0,0,0.03);
        }
      }
    }
    .text_content {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #777;
      padding: 0px 10px;
        max-width: 345px;
        margin: 0 auto;
        padding-top: 43px;
      a.read_more {
        color: #ca1e24;
        white-space: nowrap;
      }
    }
  }
  .slick__arrow {
    margin-top: 37px;
  }
}

.front-article-wrap {
  article {
    width: 50%;
    padding-right: 35px;
    float: left;
  }
}
#content {
  position: relative;
}

body.page-taxonomy-term, body.page-special, body.page-full-search {
  .front_baner_right {
    padding-top: 0px;
  }
  #page-header {
    padding-top: 86px;
    padding-bottom: 12px;
    .page-header {
      margin: 0px;
      padding: 0px;
      border: 0px;
    }
  }
  .title {
    background: url('../images/rz_title.png') left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    margin-bottom: 18px;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 49px;
  }
  .view-filters {
    position: absolute;
    right: 0px;
    top: -85px;
    width: 300px;
    #edit-field-artical-print-subjects-tid-wrapper{
      display: none;
    }
    .form-item-field-artical-print-subjects-tid {
      width: 100%;
    }
    .views-exposed-form .views-exposed-widget {
      float: none;
    }
  }
  article {
    padding-bottom: 57px;
  }
  .item-list {
    clear: both;
    ul {
      margin: 0px;
      margin-top: -20px;
    }
    li {
      a {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        border: 0px;
        box-shadow:none;
        margin-right: 3px;
      }
      &.pager-previous {
        a {
          background: url('../images/left_arroy.jpg');
        }
      }
      &.pager-next {
        a {
          background: url('../images/right_arroy.jpg');
        }
      }
      &.pager-current {
        display: none;
      }
    }
  }
  .content-row {
    .view-content {
      display: flex;
      flex-wrap: wrap;
    }
    .front-article-wrap article {
      width: 100%;
      float: none;
    }
  }
}

.row.content-row {
  position: relative;
}

.row.popular {
  background: #f2f2f2;
  margin-top: 80px;
  padding-bottom: 80px;
  .popular_block {
    .pane-title {
      min-height: 73px;
      padding-top: 69px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
    }
    .view-popular-block {
      padding-top: 39px;
      .view-content {
        display: flex;
        //justify-content: space-between;
        justify-content: flex-start;
      }
      .views-row {
        margin-right: 40px;
      }
      .slick-slide,
      .views-row {
        width: 265px;
        .popular_img_wrap {
          position: relative;
          .count_views {
            position: absolute;
            bottom: 20px;
            background: rgba(27,163,156,0.9);
            color: white;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            padding-left: 14px;
            left: 0px;
            .count {
              text-align: center;
              display: inline-block;
              width: 56px;
              position: relative;
              top: 1px;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 13px;
              height: 10px;
              background: url('../images/popular_views.png');
              position: relative;
              top: 1px;
            }
          }
        }
        .views-field-title {
          padding-top: 26px;
          a {
            color: #333;
            font-size: 13px;
            font-weight: 900;
            line-height: 17px;
            text-decoration: none;
          }
        }

      }
    }
  }
}

.views-exposed-form .views-exposed-widget {
  padding: 0px;
}

.chosen-container-single .chosen-single {
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid #1ba39c;
  color: #828282;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
  background: none;
  width: 100%;
}

.chosen-container-single .chosen-single div b {
  background: url('../images/chosen_arroy.png') 8px 9px no-repeat;
}

.chosen-container-single .chosen-single span {
  position: relative;
  top: 2px;
}
.chosen-container {
  width: 100%;
}

.chosen-container-single .chosen-drop {
  border: 1px solid #1ba39c;
}

.chosen-container .chosen-results li {
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  text-transform: uppercase;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: 0px;
  box-shadow: none;
  background: none;
  border-bottom: 2px solid #1ba39c;
}
.chosen-container-active .chosen-single {
  box-shadow: none;
}


body.node-type-page {
  .paragraphs-item-p-title-text {
    .field-name-field-p-tt-title {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 84px;
      color: #828282;
      margin-top: 87px;
      font-size: 31px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 62px;
      margin-bottom: 31px;
    }
    .field-name-field-p-tt-text{
      color: #777;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      p {
        margin-bottom: 23px;
      }
      .about_wrap {
        color: #777;
        font-family: Georgia;
        font-size: 15px;
        line-height: 28px;
        .left_img {
          float: left;
          margin-right: 58px;
          padding-top: 8px;
        }
        .right_part {
          .signature {
            text-align: right;
              /*padding-top: 12px;*/
              color: #777;
            font-family: Arial;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            span {
              position: relative;
                top: 11px;
            }
            img {
              margin-left: 24px;
            }
          }
        }
      }
      .nomer_wrap {
        &:after {
          display: block;
          clear: both;
          content: '';
        }
        padding-bottom: 21px;
        .nomer_item {
          float: left;
          min-width: 150px;
        }
      }
    }
    table {
      width: 100%;
      margin-bottom: 80px;
      thead,tbody {
        border:0px;
      }
      tr {
        border:0px;
        th {
          width: 25%;
          background: #1ba39c;
          color: white;
          padding: 15px 0px;
          border:2px solid #ffffff;
        }
        td {
          border:2px solid #ffffff;
          background: #f2f2f2;
          color: #777;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          padding: 15px 0px;
        }
      }
    }
  }
  .paragraphs-item-p-insert-block {
    padding-top: 60px;
  }
  .paragraphs-item-p-insert-block {
    .block-views {
      .block__title {
        background: url('../images/rz_title.png') left top no-repeat;
        min-height: 73px;
        padding-left: 92px;
        color: #828282;
        margin-top: 87px;
        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 49px;
        margin-bottom: 26px;
      }
      .journal_teaser {
        max-width: 212px;
        position: relative;
        text-align: center;
        .centered {
          position: absolute;
            top: 50%;
            width: 100%;
            margin-top: -13px;
        }
        a {
          color: white;
            font-size: 10px;
            font-weight: 900;
            line-height: 27px;
            text-transform: uppercase;
            background: #ca1e24;
            display: inline-block;
            padding: 0px 20px;
            letter-spacing: 1px;
            text-decoration: none;
            display: none;
        }
        &:hover {
          img {
            opacity: 0.4;
          }
          a {
            display: inline-block;
          }
        }
      }
      .slick__arrow {
        padding-top: 37px;
      }
    }

  }
  .reviews.panels-flexible-row {
    background: #f2f2f2;
    padding-top: 69px;
    margin-top: 72px;
    padding-bottom: 60px;
    padding-bottom: 150px;
    .pane-title{
      color: #828282;
      font-family: Arial;
      font-size: 45px;
      font-weight: 900;
      line-height: 45px;
      text-transform: uppercase;
      padding-bottom: 39px;
    }
    .slick__slide.slide{
      .slide__content {
        background: url('../images/rz_title.png') left top 8px no-repeat;
        padding-left: 123px;
        .views-field-title {
          font-family: Arial;
          font-size: 14px;
          color: #ca1e24;
          font-weight: 900;
          line-height: 24px;
        }
        .views-field-body {
          font-family: Arial;
          font-size: 14px;
          color: #555;
          font-weight: 400;
          line-height: 24px;
          padding-top: 17px;
        }
      }
    }
    .slick-track {
      & > .slick-slide {
        & > div {
          padding-bottom: 38px;
        }
      }
    }
    .view-review-readers {
      position: relative;
      .slick__arrow {
        position: absolute;
        top: -85px;
        right: 0px;
        button {
            box-shadow: none;
            margin-left: 3px;
            margin-right: 0px;
        }
      }
    }
  }
  .prefooter {
    /*display: none;*/
  }
  .reviews_add {
    padding-left: 124px;
    a {
      color: white;
      font-family: Arial;
      font-size: 12px;
      font-weight: 900;
      line-height: 20px;
      text-transform: uppercase;
      background: #1ba39c;
      line-height: 46px;
      display: inline-block;
      padding: 0px 54px;
    }
  }
  #block-webform-client-block-202 h4.block__title {
    margin-top: 27px;
  }
  .node-page {
    padding-bottom: 100px;
  }
}

.send_letter_to_journal {
  .block__content {
    text-align: center;
    a {
      color: white;
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
      background: #1ba39c;
      display: inline-block;
      line-height: 46px;
      padding: 0px 40px;
      text-decoration: none;
    }
  }
}
//max-width: 1199px
@media (max-width: $screen-md-max) {
  .uwstar-sidebar-taxonomy-layout {
    padding-bottom: 90px;
  }
  .front_article_wrap {
    float: none;
    width: 100%;
  }
  .front_baner_right {
    float: none;
    width: 100%;
  }
  .front_baner_right.pane-custom,
  .front_baner_right .pane-custom {
    .pane-content {
      p {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
      }
    }
  }
  .horizontal_baner {
    .pane-content {
      img {
        height: auto;
        width: 100%;
      }
    }
  }
  #footer {
    padding-top: 50px;
    .bottom_part_wrap {
      display: none;
    }
    .region-menu-bottom {
      width: 100%;
      .block__content {
        ul.menu {
          text-align: center;
          li {
            display: inline-block;
            float: none;
            margin: 0 20px;
            a {
              border-radius: 16px;
            }
          }
        }
      }
    }
    .copirate_develop {
      flex-wrap: wrap;
      .copirate {
        order: 2;
      }
      .region-social-top {
        order: 1;
        width: 100%;
        .block__content {
          ul {
            text-align: center;
            li {
              display: inline-block;
              float: none;
            }
          }
        }
      }
      .developer {
        order: 2;
      }
    }
  }
  .scrollToTop {
    display: none !important;
  }
  .prefooter {
    /*display: none;*/
  }
  .paragraphs-item-p-insert-block {
    .block__content {
      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body .left_menu {
    height: calc(100% - 407px);
    top: 169px;
  }
  .front-article-wrap {
    article {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  #footer {
    height: 238px;
  }
  body.node-type-page {
    .paragraphs-item-p-title-text {
      .field-name-field-p-tt-title {
        font-size: 30px;
        line-height: 63px;
      }
    }
  }
  body.page-taxonomy-term, body.page-special, body.page-full-search {
    .page-header {
      text-align: center;
    }
    .title {
      display: inline-block;
      font-size: 30px;
      line-height: 63px;
    }
  }
  .row.popular {
    .popular_block {
      .pane-title {
        font-size: 30px;
        padding-top: 45px;
        text-align: center;
      }
      .view-popular-block {
        .view-content {
          flex-wrap: wrap;
          justify-content: space-around;
          .views-row {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}


//max-width: 767px
@media (max-width: $screen-xs-max) {
  .container {
    width: calc(100% - 20px);
  }
  body .left_menu {
    height: calc(100% - 536px);
    top: 243px;
  }
  #footer {
    height: 293px;
    .copirate_develop {
      .copirate,
      .developer {
        padding: 5px 0;
        text-align: center;
        width: 100%;
      }
    }
  }
  body.node-type-page {
    .paragraphs-item-p-title-text {
      .field-name-field-p-tt-title {
        background-position: center top;
        padding-left: 0;
        padding-top: 80px;
        text-align: center;
        .field-items {
          display: inline-block;
          font-size: 21px;
          line-height: normal;
        }
      }
      .field-name-field-p-tt-text {
        .nomer_wrap {
          .nomer_item {
            width: 100%;
          }
        }
      }
    }
  }
  body.page-taxonomy-term, body.page-special, body.page-full-search {
    .page-header {
      text-align: center;
      .title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
    .view-filters {
      margin-bottom: 30px;
      position: initial;
      text-align: center;
      width: 100%;
    }
  }
  .front-article-wrap,
  .front_article_wrap {
    article {
      .img_title {
        .name {
          padding: 0;
          text-align: center;
        }
      }
    }
  }
  .front-article-wrap {
    article {
      float: none;
      padding: 0;
      width: 100%;
    }
  }
  .row.popular {
    .popular_block {
      .pane-title {
        font-size: 30px;
        padding-top: 45px;
        text-align: center;
      }
      .view-popular-block {
        .view-content {
          flex-wrap: wrap;
          justify-content: space-around;
          .views-row {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .old_journal {
    .rz_title  {
      text-align: center;
      .pane-title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
  }
  .view-old-journal {
    width: 100%;
    .slick-slide {
      margin: 0 35px;
    }
    .view {
      position: relative;
    }
    .slick-arrow {
      border-radius: 50%;
      height: 50px;
      opacity: 1;
      outline: none;
      position: absolute;
      text-align: center;
      top: 250px;
      width: 50px;
      &.slick-prev {
        background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
        left: -27px;
        transform: none;
      }
      &.slick-next {
        background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
        right: -27px;
      }
    }
  }
}

.ajax-progress-throbber {
    display: none;
}

.silver-modal-content,.pane-webform {
  .webform-confirmation,.links {
    text-align: center;
  }
}

.page-node-done {
  h1 {
    text-align: center;
  }
  .webform-confirmation,.links {
    text-align: center;
  }
}

.jq-selectbox ul {
  li {
    &:hover {
      text-decoration: underline;
    }
  }
}

.page-user {
  .region-content {
    max-width: 1024px;
    margin: 0 auto;
  }
}

#contacts-map {
  width: 100%;
  height: 600px;
}

.field-name-body ul {
  margin-bottom: 30px;
}