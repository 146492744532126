body.front {
  .uwstar-sidebar-layout {
    .collage {
      display: none;
    }
    .content-row {
      padding-top: 68px;
      display: flex;
      justify-content:space-between;
      &:before,&:after {
        display: none;
      }
      & > div {
      }
      .pane-slider-top-front-panel-pane-1 {
        max-width: 368px;
      }
      .journal_top_baner {
        width: 300px;
      }
      .get_journal {
        padding-top: 20px;
        .get_on_link {
          float: left;
          a {
            color: #828282;

            font-size: 15px;
            font-weight: 900;
            line-height: 20px;
            text-transform: uppercase;
            text-decoration: none;
            &:after {
              content: '';
              width: 18px;
              height: 18px;
              display: inline-block;
              background: url('../images/journal_download.png');
              position: relative;
              top: 3px;
              left: 9px;
            }
          }
        }
        .get_on_email {
          float: right;
          a {
            color: #828282;

            font-size: 15px;
            font-weight: 900;
            line-height: 20px;
            text-transform: uppercase;
            text-decoration: none;
            &:after {
              content: '';
              width: 17px;
              height: 18px;
              display: inline-block;
              background: url('../images/journal_mail.png');
              position: relative;
              top: 3px;
              left: 2px;
            }
          }
        }
      }
      .show_journal {
        clear: both;
        text-align: center;
        padding-top: 20px;
        a {
          color: white;

          font-size: 12px;
          font-weight: 900;
          line-height: 20px;
          text-transform: uppercase;
          padding: 0px 37px;
          line-height: 45px;
          background: #1ba39c;
          display: inline-block;
        }
      }
      .view-slide-front-subslide {
        .views-row {
          padding-bottom: 20px;
          padding-top: 20px;
          border-top: 1px solid #e5e5e5;
          &:nth-child(1) {
            border-top: 0px;
            padding-top: 0px;
          }
          .views-field-title {
            color: #333;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            min-height: 32px;
            a {
              color: #333;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              min-height: 32px;
              text-decoration: none;
            }
          }
          .views-field-nothing {

            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #777;
            padding-top: 13px;
            a {
              color: #ca1e24;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .medical_digest {
    padding-top: 78px;
    .medical_dagest_title {
      padding-bottom: 38px;
      .uw_block_title {
        background: url('../images/rz_title.png') left top no-repeat;
        min-height: 73px;
        padding-left: 92px;
        color: #828282;

        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 49px;
      }
    }
    .view-medical-digest {
      article {
        padding-bottom: 32px;
        .name {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #333;
           padding-bottom: 28px;
           //min-height: 76px;
           a {
            color: #333;
            text-decoration: none;
           }
          .red_text {
            color: #ca1e24;
            a {
              color: #ca1e24;
              text-decoration: none;
            }
            .border {
              padding-right: 9px;
              padding-left: 7px;
            }
          }
        }
        .field-type-image {
          float: left;
          a {
          	position: relative;
          	display: block;
          	&:before {
          		position: absolute;
          		width: 100%;
          		height: 100%;
          		top: 0px;
          		left: 0px;
          		content: '';
          		background: rgba(0,0,0,0.03);
          	}
          }
        }
        .text_content {
          padding-left: 133px;
            padding-top: 2px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #777;

          a.read_more {
            color: #ca1e24;
          }
        }

      }
    }
    .medical_digest_baner {
      width: 300px;
      float: right;
    }
    .pane-medical-digest-panel-pane-1 {
      float: left;
      max-width: 808px;
      width: calc(100% - 300px);
    }
    .slick__arrow {
      padding-top: 6px;
    }
  }

  .rz_title {
    h2 {
      background: url('../images/rz_title.png') left top no-repeat;
      min-height: 73px;
      padding-left: 92px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
    }
  }
  .row {
    &.actual,&.scientific_word,&.health_life,&.old_journal {
      padding-top: 60px;
    }
  }

  .baner3,.baner4,.baner5 {
    .horizontal_baner {
      padding-top: 68px;
    }
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.front {
    .uwstar-sidebar-layout {
      margin-bottom: 90px;
      .collage {
        margin-top: 40px;
      }
    }
    .row.old_journal {
      padding-top: 0;
    }
    .medical_digest {
      .pane-medical-digest-panel-pane-1 {
        float: none;
        width: 100%;
      }
      .slick__arrow {
        text-align: center;
      }
    }
    .front-article-wrap,
    .front_article_wrap {
      width: 100%;
      .slick-slide {
        margin: 0 35px;
      }
      article {
        max-width: 305px;
        margin-left: auto;
        margin-right: auto;
        .img_title {
          &:after {
            display: none;
          }
          img {
            height: auto;
            width: 100%;
          }
        }
        .name_front {
          a {
            display: block;
            margin-top: 25px;
            position: initial;
            text-align: center;
            height: 48px;
            overflow: hidden;
          }
        }
        .text_content {
          padding-top: 15px;
          text-align: center;
          .text_content_subwrap {
            height: 66px;
            overflow: hidden;
          }
        }
      }
      .view {
        position: relative;
      }
      .slick-arrow {
        border-radius: 50%;
        height: 50px;
        opacity: 1;
        outline: none;
        position: absolute;
        text-align: center;
        top: 107px;
        width: 50px;
        &.slick-prev {
          background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
          left: -27px;
          transform: none;
        }
        &.slick-next {
          background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
          right: -27px;
        }
      }
    }
    .medical_digest_baner,
    .front_baner_right {
      display: none;
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.front {
    .uwstar-sidebar-layout {
      .content-row {
        flex-wrap: wrap;
        .pane-journal-front-panel-pane-1 {
          order: 1;
          width: 333px;
          .node-journal {
            .field-name-field-journal-image {
              img {
                height: auto;
                width: 100%;
              }
            }
          }
        }
        .pane-slider-top-front-panel-pane-1 {
          max-width: auto;
          order: 1;
          width: 383px;
        }
        .journal_top_baner {
          margin-top: 60px;
          order: 2;
          width: 100%;
          .pane-content {
            p {
              display: flex;
              justify-content: space-around;
              width: 100%;
            }
          }
        }
      }
    }
    .medical_digest {
      .medical_dagest_title {
        text-align: center;
        .uw_block_title {
          display: inline-block;
          font-size: 30px;
          line-height: 63px;
        }
      }
    }
    .pane-collage-front {
      text-align: center;
      h2.pane-title {
        display: inline-block;
        font-size: 30px;
        line-height: 63px;
      }
      .pane-content {
        text-align: left;
      }
    }
    .rz_title {
      text-align: center;
      .pane-title {
        display: inline-block;
        font-size: 30px;
        line-height: 63px;
      }
      .pane-content {
        text-align: left;
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.front {
    .uwstar-sidebar-layout {
      .content-row {
        flex-wrap: wrap;
        .pane-journal-front-panel-pane-1 {
          order: 1;
          width: 100%;
          .node-journal {
            .field-name-field-journal-image {
              img {
                height: auto;
                width: 100%;
              }
            }
          }
        }
        .pane-slider-top-front-panel-pane-1 {
          margin-top: 60px;
          max-width: auto;
          order: 1;
          width: 100%;
          .pane-content {
            text-align: center;
          }
          .slick__arrow {
            text-align: center;
          }
        }
        .journal_top_baner {
          margin-top: 60px;
          order: 2;
          width: 100%;
          .pane-content {
            p {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              width: 100%;
            }
          }
        }
      }
    }
    .pane-collage-front {
      text-align: center;
      h2.pane-title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
      .pane-content {
        text-align: left;
      }
    }
    .rz_title {
      text-align: center;
      .pane-title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
      .pane-content {
        text-align: left;
      }
    }
    .medical_digest {
      background-color: #edf2f7;
      margin-top: 30px;
      left: -10px;
      padding: 55px 10px 60px 10px;
      position: relative;
      width: calc(100% + 20px);
      .medical_dagest_title {
        text-align: center;
        .uw_block_title {
          background-position: center top;
          display: inline-block;
          font-size: 21px;
          line-height: normal;
          padding-left: 0;
          padding-top: 80px;
        }
      }
      .view-medical-digest {
        article {
          .field-type-image {
            float: none;
            text-align: left;
            width: 100%;
            a {
              display: inline-block;
            }
            img {
              display: inline-block;
            }
          }
          .text_content {
            padding-top: 20px;
            padding-left: 0;
          }
        }
      }
    }
  }
}




