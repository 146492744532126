.node-artical-print-journal.node-teaser {
	/*.teaser_title {
		min-height: 48px;
		display: flex;
		align-items: flex-end;
	}*/
	.artical_print_journal {
		background: #ca1e24;
		color: white;
		font-size: 13px;
		font-weight: 700;
		line-height: 20px;
		text-transform: uppercase;
		margin-bottom: 15px;
	    display: inline-block;
	    padding: 0px 14px;
	}
}

.node-article-s-rz.node-teaser {
	/*.teaser_title {
		min-height: 48px;
		display: flex;
		align-items: flex-end;
	}*/
	.pre_name {
		color: white;
		font-size: 13px;
		font-weight: 700;
		line-height: 20px;
		padding-bottom: 15px;
		.special_rz {
			background: #1ba39c;
		    display: inline-block;
		    padding: 0px 14px;
		    margin-right: 3px;
		}	
		.date {
			background: rgba(51,51,51,0.5);
		    display: inline-block;
		    padding: 0px 14px;
		}	
	}
}


.front_article_wrap article.node-teaser, 
.front-article-wrap article.node-teaser {
	.fixion_title {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		z-index: 2;
	}
	.img_title .name {
		z-index: 3;
	}
	.teaser_title a {
		color: #333;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		word-spacing: 1px;
		text-decoration: none;
	}
}