body.page-infographic {
  .view-infographic {
    position: relative;
  }
  .page-header {
    margin-bottom: 0px;
      padding-bottom: 11px;
      border: 0px;
  }
  .title {
    background: url('../images/rz_title.png') left top no-repeat;
    min-height: 73px;
    padding-left: 92px;
    color: #828282;
    margin-bottom: 18px;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 49px;
    margin-top: 87px;
      padding-left: 83px;
  }
  .view-filters {
    position: absolute;
    right: 0px;
    top: -85px;
    width: 300px;
    .form-item-field-artical-print-subjects-tid {
      width: 100%;
    }
    .views-exposed-form .views-exposed-widget {
      float: none;
    }
    select {
      width: 100%;
    }
  }
  .view-content {
    .first_part, .second_part {
      .views-row {
        text-align: center;
        max-width: 212px;
        float: left;
        margin-right: 30px;
        &:nth-child(5n) {
          margin-right: 0px;
        }
        &:nth-child(5n+1) {
          clear: both;
        }
        .views-field-title {
          padding-top: 15px;
          padding-bottom: 55px;
          a {
            color: #828282;
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
      }
    }
    .node-infographics {
      max-width: 212px;
      position: relative;
      text-align: center;
      padding-bottom: 52px;
      .img_title {
        position:relative;
        box-shadow: 5.4px 5.9px 8px rgba(0, 0, 0, 0.08);
        .centered {
          background: rgba(27,163,156,0.6);
          position:absolute;
          top: 0px;
          height: 100%;
          width: 100%;
          display: none;
          .pre_name {
            position: absolute;
              top: 50%;
              width: 100%;
              margin-top: -13px;
          }
        }
        a {
          color: white;
            font-size: 10px;
            font-weight: 900;
            line-height: 27px;
            text-transform: uppercase;
            background: #1ba39c;
            display: inline-block;
            padding: 0px 20px;
            letter-spacing: 1px;
            text-decoration: none;
        }
      }
      &:hover {
        .img_title {
          .centered {
            display: block;
          }
        }
        .artical_print_journal {
          background: #1ba39c;
        }
      }
      .artical_print_journal {
        background: #ca1e24;
        color: white;
        margin-top: 20px;
        display: inline-block;
        padding: 0px 14px;
      }
      .title_info {
        padding-top:11px;
        a {
          font-size: 12px;
          font-weight: 900;
          color: #828282;
          text-transform: uppercase;
          padding: 0px 15px;
            min-height: 36px;
        }
      }
    }
    .second_part {
      clear: both;
    }
  }
  .all_journal_b {
    padding-bottom: 80px;
      clear: both;
      padding-top: 29px;
  }
  .item-list {
    clear: both;
    padding-top: 18px;
    ul {
      margin: 0px;
      margin-top: -20px;
    }
    li {
      a {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        border: 0px;
        box-shadow:none;
        margin-right: 3px;
      }
      &.pager-previous {
        a {
          background: url('../images/left_arroy.jpg');
        }
      }
      &.pager-next {
        a {
          background: url('../images/right_arroy.jpg');
        }
      }
      &.pager-current {
        display: none;
      }
    }
  }
  .horizontal_baner {
    padding-top: 55px;
  }
  .popular_block {
    .pane-title {
      min-height: 73px;
      padding-top: 51px;
      color: #828282;
      margin-bottom: 18px;
      font-size: 45px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 49px;
    }
    .view-popular-block {
      padding-top: 39px;
      .view-content {
        display: flex;
        justify-content: space-between;
      }
      .slick {
        width: 100%;
      }
      .views-row,
      .slick-slide {
        width: 265px;
        .popular_img_wrap {
          position: relative;
          .count_views {
            position: absolute;
            bottom: 20px;
            background: rgba(27,163,156,0.9);
            color: white;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            padding-left: 14px;
            .count {
              text-align: center;
              display: inline-block;
              width: 56px;
              position: relative;
              top: 1px;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 13px;
              height: 10px;
              background: url('../images/popular_views.png');
              position: relative;
              top: 1px;
            }
          }
        }
        .views-field-title {
          padding-top: 26px;
          a {
            color: #333;
            font-size: 13px;
            font-weight: 900;
            line-height: 17px;
            text-decoration: none;
          }
        }

      }
    }
  }

}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.page-infographic {
    .popular_block {
      .slide__content {
        margin: 0 auto;
        width: 265px;
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.page-infographic {
    .title {
      font-size: 30px;
      line-height: 63px;
    }
    .view-id-infographic {
      .view-content {
        .second_part,
        .first_part {
          display: inline-block;
          width: 100%;
          .views-row {
            margin-right: 55px;
            &:nth-child(5n+1) {
              clear: none;
              margin-right: 55px;
            }
            &:nth-child(3n+1) {
              clear: both;
              margin-right: 55px;
            }
            &:nth-child(3n+3) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .popular_block {
      padding-bottom: 60px;
      padding-top: 60px;
      position: relative;
      .pane-title {
        border-top: 0;
        font-size: 31px;
        font-weight: 900;
        margin-top: 0;
        padding-top: 0;
        text-align: center;
      }
      .view-popular-block {
        padding-top: 0;
        position: relative;
        .popular_img_wrap {
          img {
            height: auto;
            width: 100%;
          }
        }
        .slick-slider {
          margin: 0 auto;
          width: 654px;
        }
        .slick-slide {
          padding: 0 20px;
        }
        .slick-arrow {
          border-radius: 50%;
          height: 50px;
          opacity: 1;
          outline: none;
          position: absolute;
          text-align: center;
          top: 40%;
          width: 50px;
          z-index: 2;
          &.slick-prev {
            background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
            left: -27px;
            transform: translateY(-50%);
          }
          &.slick-next {
            background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
            right: -27px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.page-infographic {
    .page-header {
      text-align: center;
      .title {
        background-position: center top;
        display: inline-block;
        font-size: 21px;
        line-height: normal;
        padding-left: 0;
        padding-top: 80px;
      }
    }
    .view-id-infographic {
      .view-filters {
        margin-bottom: 30px;
        position: initial;
        text-align: center;
        width: 100%;
      }
      .view-content {
        .first_part,
        .second_part {
          display: inline-block;
          text-align: center;
          width: 100%;
          .views-row {
            display: inline-block;
            float: none;
            margin-right: 0;
            &:nth-child(5n+1) {
              clear: none;
              margin-right: 0;
            }
          }
        }
        .node-infographics {
          .title_info {
            a {
              display: block;
              padding: 0;
              width: 100%;
            }
          }
        }
      }
    }
    .horizontal_baner {
      padding-top: 40px;
    }
    .popular_block {
      padding-bottom: 60px;
      padding-top: 20px;
      position: relative;
      .pane-title {
        font-size: 21px;
        font-weight: 900;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 30px;
        text-align: center;
      }
      .view-popular-block {
        position: relative;
        .slick-track {
          width: 100% !important;
        }
        .slick-slide {
          float: none;
          width: 100% !important;
        }
        .slick__arrow {
          display: none;
        }
      }
    }
  }
}

.nogoogle-nomap,
.nogoogle-nomap-overlay {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.nogoogle-nomap {
  background: transparent url(../images/infographic/bg.png) left top;
  z-index: 19001;
}
.nogoogle-nomap-overlay {
  background: transparent url(../images/infographic/b.gif) left top repeat;
  cursor: url(http://api-maps.yandex.ru/2.0.5/images/img0), url(http://api-maps.yandex.ru/2.0.5/images/img0), move;
}
.nogoogle-nomap-content {
  display: block;
  position: absolute;
  transform: rotate(0deg);
}
.nogoogle-nomap-slider {
  background: transparent url(../images/infographic/zoom-bg.png) 29px top no-repeat;
  cursor: pointer;
  height: 178px;
  left: 0;
  padding-left: 29px;
  position: absolute;
  top: 25px;
  width: 62px;
  > div {
    background: transparent url(../images/infographic/zoom-cursor.png) left top no-repeat;
    height: 8px;
    margin-top: 24px;
    position: absolute;
    right: 3px;
    top: 0;
    width: 33px;
  }
  span {
    background: transparent url(../images/infographic/b.gif) left top repeat;
    display: block;
    height: 28px;
    position: absolute;
    right: 0;
    width: 33px;
  }
}
.nogoogle-nomap-close {
  background: transparent url(../images/infographic/close.png) left 15px no-repeat;
  cursor: pointer;
  height: 65px;
  position: absolute;
  right: 0;
  top: 0;
  width: 172px;
}

body.node-type-infographics{
  .most_read{
    background-color: #f2f2f2;
    position: relative;
    padding: 80px 0;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -300%;
      width: 300%;
      height: 100%;
      background-color: #f2f2f2;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: -300%;
      width: 300%;
      height: 100%;
      background-color: #f2f2f2;
    }
    h2.pane-title{

    }
    .view-v-most-read{
      .view-content{
        .slick-slider{
          .slick__slide{
            @media screen and (min-width: $screen-lg) {
              margin-right: 40px;
              margin-bottom: 0px;
              float: left;
              width: 265px!important;
              &:nth-child(4n+4){
                margin-right: 0px;
              }
            }
            .views-field-body{
              a{
                @media screen and (min-width: $screen-lg) {
                  margin-top: 16px;
                  display: block;
                }
              }
            } 
          }
        }
      }
    }
  }
}