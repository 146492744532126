body.node-type-artical-print-journal,body.node-type-article-s-rz {
  .most_read{
    background-color: #f2f2f2;
    position: relative;
    padding: 80px 0;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -300%;
      width: 300%;
      height: 100%;
      background-color: #f2f2f2;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: -300%;
      width: 300%;
      height: 100%;
      background-color: #f2f2f2;
    }
    h2.pane-title{

    }
    .view-v-most-read{
      .view-content{
        .slick-slider{
          .slick__slide{
            @media screen and (min-width: $screen-lg) {
              margin-right: 40px;
              margin-bottom: 0px;
              float: left;
              width: 265px!important;
              &:nth-child(4n+4){
                margin-right: 0px;
              }
            }
            .views-field-body{
              a{
                @media screen and (min-width: $screen-lg) {
                  margin-top: 16px;
                  display: block;
                }
              }
            } 
          }
        }
      }
    }
  }
}
body.node-type-artical-print-journal,body.node-type-article-s-rz,body.node-type-infographics {
  .uwstar-sidebar-article-layout {
    .front_article_wrap {
      max-width: 825px;
    }
    .front_baner_right {
      padding-top: 0px;
    }
    .socials_button {
      padding-top: 16px;
    }
    .content_wrap {
      position: relative;
      overflow: hidden;
    }
    .article-rm-gradietn{
      width: 100%;
      height: 163px;
      position: absolute;
      bottom: 15px;
      left: 0;
      background: url('../images/bg-article-gradient.png') no-repeat;
    }
    .article-rm-btn{
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      background: #fff;
      color: #ca1e24;
      font-size: 11px;
      font-weight: 900;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
    }
    .field-name-body {
      color: #777;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding-top: 37px;
      p {
        margin-bottom: 28px;
      }
      img {
        max-width: 100%;
        height: auto;
        padding-right:10px;
        padding-bottom:10px;
      }
    }
    .field-name-field-artical-print-subjects {
      padding-top: 27px;
      .field-label {
        font-size: 14px;
        font-weight: 900;
        padding-right: 10px;
        color: #828282;
        text-transform: uppercase;
      }
      .field-item {
        display: inline-block;
      }
      a {
        font-size: 14px;
        font-weight: 900;
        color: #1ba39c;
        line-height: 22px;
        text-decoration: underline;
        margin-right: 14px;
          text-transform: lowercase;
      }
    }
    padding-top: 68px;
    .img_wrap {
      float: left;
      max-width: 300px;
      position: relative;
      margin-right: 40px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      .artical_print_journal {
        color: white;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        background: #ca1e24;
        position: absolute;
        top: 21px;
        right: 20px;
        padding: 0px 14px;
      }
    }
    h1 {
      color: #828282;
      font-size: 31px;
      font-weight: 900;
      line-height: 31px;
      text-transform: uppercase;
      margin-top: 0px;
    }
    #vk_like {
      float: left!important;
      clear: inherit!important;
    }
    .popular_block {
      .pane-title {
        min-height: 73px;
          margin-top: 58px;
          padding-top: 37px;
          margin-bottom: 32px;
        border-top: 4px solid #ca1e24;
        color: #ca1e24;
        font-size: 21px;
        font-weight: 900;
        line-height: 31px;
        text-transform: uppercase;
      }
      .view-popular-block {
        .views-field-nothing {
          position: relative;
          width: 265px;
        }
        .views-field-title {
          width: 265px;
        }
        .views-row,
        .slick-slide {
          width: 265px;
          padding-bottom: 34px;
        }
        .popular_img_wrap {
          position: relative;
          .count_views {
            position: absolute;
            bottom: 20px;
            background: #c9292d;
            color: white;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            padding-left: 14px;
            left: 0px;
            .count {
              text-align: center;
              display: inline-block;
              width: 56px;
              position: relative;
              top: 1px;
            }
            &:before {
              content: '';
              display: inline-block;
              width: 13px;
              height: 10px;
              background: url('../images/popular_views.png');
              position: relative;
              top: 1px;
            }
          }
        }
        .views-field-title {
          padding-top: 26px;
          a {
            color: #333;
            font-size: 13px;
            font-weight: 900;
            line-height: 17px;
            text-decoration: none;
          }
        }
      }
    }
    .row.comment {
      margin-top: 85px;
      background: #f2f2f2;
      padding-bottom: 104px;
      .comments_specialist {
        .view-specialist-opinion {
          position: relative;
        }
        .slick__arrow {
          position: absolute;
          top: -38px;
          right: 0px;
          button {
            margin-left: 3px;
            margin-right: 0px;
          }
        }
        .pane-title {
          color: #828282;
          font-size: 45px;
          font-weight: 900;
          line-height: 45px;
          text-transform: uppercase;
          padding-top: 61px;
          max-width: 500px;
        }
        .slick {
            padding-top: 35px;
          .field-name-field-specialist {
            float: left;
            width: 151px;
              margin-right: 70px;
              text-align: center;
              img {
                display: block;
                border-radius: 50%;
              }
            .specialist_title {
              color: #1ba39c;
              font-size: 14px;
              font-weight: 900;
              padding-top: 27px;
              line-height: 16px;
            }
            .content {
              color: #555;
              font-size: 13px;
              font-weight: 400;
              padding-top: 5px;
              line-height: 16px;
            }
          }
          .field-name-field-opinion {
            min-height: 254px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #555;
            p {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
    .rz_title {
      .pane-title {
        background: url('../images/rz_title.png') left top no-repeat;
        min-height: 73px;
        padding-left: 92px;
        color: #828282;
        margin-bottom: 18px;
        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 49px;
      }
    }
    .related_article,.random_article {
      clear: both;
      padding-top: 70px;
      .view-related-articles,.view-random-article {
        padding-top: 8px;
        .views-row {
          width: 50%;
          float: left;
          &:nth-child(3) {
            clear: both;
          }
          &:nth-child(2n) {
            padding-left: 36px;
          }
          &:nth-child(2n-1) {
            padding-right: 36px;
            padding-left: 0px;
          }
        }
        article {
          padding-bottom: 75px;
          .name {
            min-height: 45px;
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;
              color: #828282;
              margin-bottom: 31px;
            .red_text {
              color: #ca1e24;
              a{
                text-decoration:none;
                color: #ca1e24;
              }
              .border {
                padding-right: 9px;
                padding-left: 7px;
              }
            }
          }
          .field-type-image {
            float: left;
          }
          .text_content {
            padding-left: 213px;
              padding-top: 2px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #777;
            position: relative;
              top: -7px;
          }
          a.read_more {
            color: #ca1e24;
              white-space: nowrap;
              display: inline-block;
              margin-left: 39px;
              padding-top: 6px;
          }

        }
      }

    }
    .random_article {
      &.rz_title {
        padding-top: 2px;
      }
      .view-random-article {
        padding-top: 0px;
        .views-row {
          padding: 0px;
          width: 100%;
          float: none;
          article {
            padding-bottom: 11px;
          }
          .name {
            margin-bottom: 26px;
              min-height: initial;
          }
          .text_content {
            padding-top: 15px;
          }
        }
      }

    }
  }
  .social-btns {
    float: left;
  }
}

body.node-type-article-s-rz {
  .uwstar-sidebar-article-layout {
    .front_article_wrap {
      max-width: 815px;
    }
    .img_wrap {
      margin: 0px;
      width: 100%;
      max-width: inherit;
      float: none;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .socials_button {
        position: absolute;
        bottom: 16px;
        width: 100%;
        padding: 0px 40px;
        #vk_like {
          top: -1px;
        }
        .pre_name {
          float: left;
          color: white;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          padding-bottom: 15px;
          padding-right: 10px;
          a{
                text-decoration: none;
                color: white;
           }
          .special_rz {
            background: #1ba39c;
              display: inline-block;
              padding: 0px 14px;
              margin-right: 3px;

          }
          .date {
            background: rgba(51,51,51,0.5);
              display: inline-block;
              padding: 0px 14px;
          }
        }
      }
    }
    h1 {
      padding-top: 35px;
    }
    .field-name-body {
      padding-top: 26px;
    }
  }
}

body.node-type-infographics {
  .uwstar-sidebar-article-layout  {
    .popular_block {
      .pane-title {
        min-height: 73px;
        padding-top: 69px;
        color: #828282;
        margin-bottom: 18px;
        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 49px;
        margin-top: 0px;
        border-top: 0px;
      }
      .view-popular-block {
        padding-top: 39px;
        .view-content {
          display: flex;
          justify-content: space-between;
        }
        .views-row {
          width: 265px;
          .popular_img_wrap {
            position: relative;
            .count_views {
              position: absolute;
              bottom: 20px;
              left: 0px;
              background: rgba(27,163,156,0.9);
              color: white;
              font-size: 13px;
              font-weight: 700;
              line-height: 20px;
              text-transform: uppercase;
              padding-left: 14px;
              .count {
                text-align: center;
                display: inline-block;
                width: 56px;
                position: relative;
                top: 1px;
              }
              &:before {
                content: '';
                display: inline-block;
                width: 13px;
                height: 10px;
                background: url('../images/popular_views.png');
                position: relative;
                top: 1px;
              }
            }
          }
          .views-field-title {
            padding-top: 26px;
            a {
              color: #333;
              font-size: 13px;
              font-weight: 900;
              line-height: 17px;
              text-decoration: none;
            }
          }

        }
      }
    }
    .infographic_text {
      color: white;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      background: #1ba39c;
      display: inline-block;
      padding: 0px 14px;
    }
    h1 {
      padding-top: 10px;
    }
    .field-name-field-content-image {
      margin-top: 37px;
      border:1px solid #ebebec;
      .field-item {
        /*padding-top:37px;
        padding-bottom: 9px;*/
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .front_article_wrap {
      max-width: 815px;
    }
  }
  .get_journal {
    padding-top: 20px;
    .get_on_link {
      float: left;
      a {
        color: #828282;

        font-size: 15px;
        font-weight: 900;
        line-height: 20px;
        text-transform: uppercase;
        text-decoration: none;
        &:after {
          content: '';
          width: 18px;
          height: 18px;
          display: inline-block;
          background: url('../images/journal_download.png');
          position: relative;
          top: 3px;
          left: 9px;
        }
      }
    }
    .get_on_email {
      margin-left: 65px;
      float: left;
      a {
        color: #828282;

        font-size: 15px;
        font-weight: 900;
        line-height: 20px;
        text-transform: uppercase;
        text-decoration: none;
        &:after {
          content: '';
          width: 17px;
          height: 18px;
          display: inline-block;
          background: url('../images/journal_mail.png');
          position: relative;
          top: 3px;
          left: 2px;
        }
      }
    }
  }
}


body.node-type-journal {
  .content-row {
    padding-top: 68px;
    display: flex;
    justify-content:space-between;
    &:before,&:after {
      display: none;
    }
    & > div {
    }
    .main_theme_journal {
      max-width: 382px;
      .pane-title {
        color: #ca1e24;
        font-size: 21px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        margin: 0px;
      }
      .views-field-title {
        color: #828282;
        font-size: 51px;
        font-weight: 900;
        line-height: 51px;
        text-transform: uppercase;
        padding-top:14px;
      }
      .views-field-body {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #777;
        padding-top: 53px;
        a {
          color: #ca1e24;
        }
      }
    }
    .journal_top_baner {
      width: 300px;
    }
    .get_journal {
      padding-top: 20px;
      .get_on_link {
        float: left;
        a {
          color: #828282;

          font-size: 15px;
          font-weight: 900;
          line-height: 20px;
          text-transform: uppercase;
          text-decoration: none;
          &:after {
            content: '';
            width: 18px;
            height: 18px;
            display: inline-block;
            background: url('../images/journal_download.png');
            position: relative;
            top: 3px;
            left: 9px;
          }
        }
      }
      .get_on_email {
        float: right;
        a {
          color: #828282;

          font-size: 15px;
          font-weight: 900;
          line-height: 20px;
          text-transform: uppercase;
          text-decoration: none;
          &:after {
            content: '';
            width: 17px;
            height: 18px;
            display: inline-block;
            background: url('../images/journal_mail.png');
            position: relative;
            top: 3px;
            left: 2px;
          }
        }
      }
    }
    .show_journal {
      display: none;
    }
  }

  .content_center {
    padding-top: 139px;
    display: flex;
    justify-content:space-between;
    &:before,&:after {
      display: none;
    }
    .content_center_list {
      max-width: 815px;
      .pane-title {
        color: #ca1e24;
        font-size: 45px;
        font-weight: 900;
        text-transform: uppercase;
      }
    }
    .content_center_baner {
      width: 300px;
      padding-top: 128px;
    }
    .view-content-journal {
      font-size: 15px;
      line-height: 26px;
      padding-top: 13px;
      .view-content {
        columns: 2;
        -webkit-column-gap: 30px; /* Chrome, Safari, Opera */
          -moz-column-gap: 30px; /* Firefox */
          column-gap: 30px;
      }
      h3 {
        color: #333;
        font-weight: 900;
        font-size: 15px;
        text-transform: uppercase;
        margin-bottom: 5px;
        padding-top: 30px;
        margin-top: 0px;
      }
      .views-field-field-article-number {
        float: left;
        color: #ca1e24;
        font-weight: 900;
        padding-right:5px;
      }
      .views-field-title {
        a {
          color: #777;
          font-weight: 400;
          text-decoration: none;
        }
      }
      .wrap_rows {
        min-height: 130px;
      }
    }
  }
}

body.node-type-artical-print-journal,body.node-type-article-s-rz {
  article.vertical {
    .img_wrap {
      float: left;
      max-width: 300px;
      position: relative;
      margin-right: 40px;
      color: white;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      .pre_name {
        position: absolute;
        top: 21px;
        padding-right: 20px;
        width: 100%;
        text-align: right;
        .artical_print_journal {
          color: white;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          text-transform: uppercase;
          background: #ca1e24;
          padding: 0px 14px;
        }
        a{
                text-decoration: none;
                color: white;
           }
        .special_rz {
          background: #1ba39c;
            display: inline-block;
            padding: 0px 14px;
        }
        .date {
          background: rgba(51,51,51,0.5);
            display: inline-block;
            padding: 0px 14px;
        }
      }
      @media (max-width: 767px) { 
        float: none;
      }      
    }
    h1 {
      color: #828282;
      font-size: 31px;
      font-weight: 900;
      line-height: 31px;
      text-transform: uppercase;
      margin-top: 0px;
      padding-top: 0px;
      margin-left: 341px;
      word-wrap: break-word;
      @media (max-width: 767px) { 
        margin-left: 0px;
        padding-top: 10px;
      } 
    }
  }
  article.horizontal {
    .img_wrap {
      margin: 0px;
      width: 100%;
      max-width: inherit;
      float: none;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .socials_button {
        position: absolute;
        bottom: 16px;
        width: 100%;
        padding: 0px 40px;
        #vk_like {
          top: -1px;
        }
        .pre_name {
          float: left;
          color: white;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          padding-bottom: 15px;
          padding-right: 10px;
          a{
                text-decoration: none;
                color: white;
           }
          .special_rz {
            background: #1ba39c;
              display: inline-block;
              padding: 0px 14px;
              margin-right: 3px;
          }
          .date {
            background: rgba(51,51,51,0.5);
              display: inline-block;
              padding: 0px 14px;
          }
          .artical_print_journal {
            position: inherit;
            top: auto;
            display: inline-block;
          }
        }
      }
    }
    h1 {
      padding-top: 35px;
    }
    .field-name-body {
      padding-top: 26px;
    }
  }
}

.pane-v-most-read-panel-pane-1,
.pane-v-most-read-panel-pane-2,
.pane-v-most-read-panel-pane-3,
.pane-v-most-read-panel-pane-4{
  margin: 52px 0 0 0;
  h2.pane-title{
    color: #189891;
    font-family: Arial;
    font-size: 31px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    font-style: normal;
  }
}

.view-v-most-read {
  margin: 31px 0 0 0;
  .view-content{
    .views-row,
    .slick-slide {
      margin-bottom: 33px;
      &:last-child {
        margin-bottom: 0px;
      }
      .views-field-created{
        display: inline;
        color: #189891;
        font-weight: 700;
        font-size: 16px;
      }
      .views-field-title{
        display: inline;
        padding: 0 0 0 8px;
        line-height: 1.3;
        a{
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }
      }
      .views-field-body{
        font-size: 14px;
        font-weight: 400;
        color: #777;
        margin-top: 10px;
        a{
          color: #189891;
        }
      }
    }
  }
}

//min-width: 1200px
@media (min-width: $screen-lg) {
  .view-v-most-read {
    .view-content{
      .slick-slide {
        float: none;
        width: 300px !important;
      }
      .slick__arrow {
        display: none;
      }
    }
  }
  body.node-type-artical-print-journal,body.node-type-article-s-rz {
    .uwstar-sidebar-article-layout {
      .popular_block {
        .view-popular-block {
          .slick-slide {
            float: none;
            width: 300px !important;
          }
          .slick__arrow {
            display: none;
          }
        }
      }
    }
  }
  body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      .popular_block {
        .view-popular-block {
          .slick {
            width: 100%;
            .slide__content {
              margin: 0 auto;
              width: 265px;
            }
          }
        }
      }
    }
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  body.node-type-artical-print-journal,body.node-type-article-s-rz,body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      padding-bottom: 90px;
      .front_article_wrap {
        width: 100%;
      }
      .front_baner_right {
        margin-top: 60px;
        width: 100%;
      }
      .pane-custom {
        .pane-content {
          p {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 100%;
          }
        }
      }
      .popular_block {
        .view-popular-block {
          .views-field-nothing,
          .views-field-title {
            margin: 0 auto;
          }
        }
      }
      .related_article {
        .view-related-articles {
          .views-row {
            padding: 0 !important;
            width: 100% !important;
          }
          article {
            padding-bottom: 45px;
            .name {
              min-height: auto;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
  body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      .popular_block {
        .view-popular-block {
          .slick {
            width: 100%;
          }
        }
      }
    }
  }
  body.node-type-journal {
    .uwstar-sidebar-layout {
      padding-bottom: 90px;
    }
    .content-row {
      flex-wrap: wrap;
    }
    .content_center {
      flex-wrap: wrap;
      .content_center_baner {
        padding-top: 40px;
        width: 100%;
        .pane-content {
          text-align: center;
        }
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  body.node-type-journal {
    .content-row {
      .pane-node {
        width: 50%;
        .field-name-field-journal-image {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
      .main_theme_journal {
        padding-left: 50px;
        width: 50%;
        .views-field-title {
          font-size: 30px;
        }
        .views-field-body {
          padding-top: 20px;
        }
      }
      .journal_top_baner {
        margin-top: 30px;
        width: 100%;
        .pane-content {
          margin: 0 auto;
          width: 300px;
        }
      }
    }
    .content_center {
      padding-top: 40px;
      .content_center_list {
        width: 100%;
        .pane-title {
          font-size: 30px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  body.node-type-artical-print-journal,body.node-type-article-s-rz,body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      .rz_title {
        text-align: center;
        .pane-title {
          display: inline-block;
          font-size: 30px;
          line-height: 63px;
        }
        .pane-content {
          text-align: left;
        }
      }
      .row.comment {
        margin-top: 0;
      }
      .pane-v-most-read-panel-pane-1 {
        background: #f2f2f2;
        padding-bottom: 60px;
        padding-top: 60px;
        position: relative;
        &:before {
          content: '';
          background: #f2f2f2;
          left: -1000px;
          height: 100%;
          position: absolute;
          top: 0;
          width: 1000px;
        }
        &:after {
          content: '';
          background: #f2f2f2;
          height: 100%;
          position: absolute;
          right: -1000px;
          top: 0;
          width: 1000px;
        }
        .pane-title {
          text-align: center;
        }
      }
      .view-v-most-read {
        margin: 31px 0 0 0;
        position: relative;
        .slick-slider {
          margin: 0 auto;
          width: 654px;
        }
        .slick-slide {
          padding: 0 20px;
        }
        .slick-arrow {
          border-radius: 50%;
          height: 50px;
          opacity: 1;
          outline: none;
          position: absolute;
          text-align: center;
          top: 40%;
          width: 50px;
          z-index: 2;
          &.slick-prev {
            background: #1ba39c url(../images/arrow_left.png) center no-repeat;
            left: -27px;
            transform: translateY(-50%);
          }
          &.slick-next {
            background: #1ba39c url(../images/arrow_right.png) center no-repeat;
            right: -27px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  body.node-type-artical-print-journal, body.node-type-article-s-rz, body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      .popular_block {
        padding-bottom: 60px;
        padding-top: 60px;
        position: relative;
        .pane-title {
          border-top: 0;
          font-size: 31px;
          font-weight: 900;
          margin-top: 0;
          padding-top: 0;
          text-align: center;
        }
        .view-popular-block {
          padding-top: 0;
          position: relative;
          .popular_img_wrap {
            img {
              height: auto;
              width: 100%;
            }
          }
          .slick-slider {
            margin: 0 auto;
            width: 654px;
          }
          .slick-slide {
            padding: 0 20px;
          }
          .slick-arrow {
            border-radius: 50%;
            height: 50px;
            opacity: 1;
            outline: none;
            position: absolute;
            text-align: center;
            top: 40%;
            width: 50px;
            z-index: 2;
            &.slick-prev {
              background: #ca1e24 url(../images/arrow_left.png) center no-repeat;
              left: -27px;
              transform: translateY(-50%);
            }
            &.slick-next {
              background: #ca1e24 url(../images/arrow_right.png) center no-repeat;
              right: -27px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  body.node-type-journal {
    .content-row {
      .pane-node {
        width: 100%;
        .field-name-field-journal-image {
          img {
            height: auto;
            width: 100%;
          }
        }
      }
      .main_theme_journal {
        width: 100%;
        .pane-title {
          display: none;
        }
        .views-field-title {
          font-family: "Arial Black";
          font-size: 29px;
          font-weight: 400;
          line-height: normal;
        }
        .views-field-body {
          padding-top: 20px;
        }
      }
      .journal_top_baner {
        margin-top: 30px;
        width: 100%;
        .pane-content {
          margin: 0 auto;
          width: 300px;
        }
      }
      .get_journal {
        border-bottom: 1px solid #1ba39c;
        padding-bottom: 35px;
        .get_on_link {
          float: none;
          text-align: center;
          width: 100%;
        }
        .get_on_email {
          float: none;
          margin-top: 10px;
          text-align: center;
          width: 100%;
        }
      }
    }
    .content_center {
      padding-top: 40px;
      .view-content-journal {
        .view-content {
          columns: 1;
        }
        .wrap_rows {
          min-height: auto;
        }
      }
      .content_center_list {
        width: 100%;
        .pane-title {
          font-family: "Arial Black";
          font-style: normal;
          font-size: 35px;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
  body.node-type-artical-print-journal,body.node-type-article-s-rz,body.node-type-infographics {
    .uwstar-sidebar-article-layout {
      h1 {
        font-size: 20px;
        line-height: normal;
      }
      .rz_title {
        text-align: center;
        .pane-title {
          background-position: center top;
          display: inline-block;
          font-size: 21px;
          line-height: normal;
          padding-left: 0;
          padding-top: 80px;
        }
        .pane-content {
          text-align: left;
        }
      }
      .row.comment {
        padding-bottom: 0;
        margin-top: 0;
        .comments_specialist {
          .pane-title {
            font-size: 30px;
            line-height: normal;
            text-align: center;
          }
          .slick-slider {
            padding-top: 60px;
            .field-name-field-specialist {
              float: none;
              text-align: center;
              width: 100%;
              img {
                display: inline-block;
              }
            }
            .field-name-field-opinion {
              margin-top: 20px;
              text-align: center;
            }
          }
          .slick__arrow {
            top: 15px;
            text-align: center;
            width: 100%;
          }
        }
      }
      .popular_block {
        padding-bottom: 60px;
        padding-top: 60px;
        position: relative;
        .pane-title {
          font-size: 21px;
          font-weight: 900;
          margin-top: 0;
          padding-top: 30px;
          text-align: center;
        }
        .view-popular-block {
          position: relative;
          .slick-track {
            width: 100% !important;
          }
          .slick-slide {
            float: none;
            width: 100% !important;
          }
          .slick__arrow {
            display: none;
          }
        }
      }
      .view-v-most-read {
        .view-content {
          .slick-track {
            width: 100% !important;
          }
          .slick-slide {
            float: none;
            width: 100% !important;
          }
          .slick__arrow {
            display: none;
          }
        }
      }
      .related_article {
        .view-related-articles {
          article {
            text-align: center;
            .field-name-field-image-long {
              float: none;
              width: 100%;
              img {
                display: inline-block;
              }
            }
            .text_content {
              float: none;
              margin-top: 20px;
              padding-left: 0;
              top: 0;
              width: 100%;
            }
            a.read_more {
              margin-left: 0;
            }
          }
        }
      }
      .random_article {
        .view-random-article {
          article {
            text-align: center;
            .field-name-field-image-long {
              float: none;
              width: 100%;
              img {
                display: inline-block;
              }
            }
            .text_content {
              float: none;
              margin-top: 20px;
              padding-left: 0;
              top: 0;
              width: 100%;
            }
            a.read_more {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  body.node-type-article-s-rz {
    .uwstar-sidebar-article-layout {
      .img_wrap {
        margin-bottom: 50px;
        .socials_button {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          .pre_name {
            float: none;
            padding: 0;
          }
          .social-btns {
            position: absolute;
            top: 50px;
          }
        }
      }
    }
  }
}

body.node-type-infographics{
  .front_article_wrap{
    max-width: 100%!important;
    width: 100%;
    float: none;
  }
  .front_baner_right{
    display: none;
  }
}