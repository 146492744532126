@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

header {
  background: #1ba39c;
  padding-top: 17px;
  height: 144px;
  .logo_wrap {
    padding-top: 2px;
    float: left;
    width: 153px;
    position: relative;
    .logo {
      position: absolute;
      top: 0px;
      left: 0px;
    }
    #logo {
      float: none;
      width: auto;
    }
  }
  .slogan_wrap {
    color: white;

    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
    float: left;
      padding-left: 20px;
      padding-top: 4px;
  }
  .right_part_wrap {
    float: right;
    .top_part_wrap {
      padding-top: 3px;
      & > div {
        float: right;
      }
    }
    .region-social-top {
      width: 68px;
      margin-right: 27px;
      .block__content {
        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          li {
            display: block;
              float: left;
              padding: 0px;
              width: 34px;
              height: 34px;
            a {
              display: inline-block;
              width: 32px;
              height: 32px;
              margin-bottom: 2px;
              margin-left: 2px;
              &.vk {
                background: url('../images/vk.png');
                &:hover {
                  background: url('../images/vk_active.jpg');
                }
              }
              &.fb {
                background: url('../images/fb.png');
                &:hover {
                  background: url('../images/fb_active.jpg');
                }
              }
              &.gp {
                background: url('../images/gp.png');
                &:hover {
                  background: url('../images/gp_active.jpg');
                }
              }
              &.yt {
                background: url('../images/yt.png');
                &:hover {
                  background: url('../images/yt_active.jpg');
                }
              }
            }
          }
        }
      }
    }
    .language_find {
      .block__content {
        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          li {
            display: inline-block;
            a {
              display: inline-block;
              width: 27px;
              height: 27px;
              background: #157a75;
              color: #7ed0cc;

              font-size: 8px;
              font-weight: 900;
              line-height: 18px;
              text-transform: uppercase;
              border-radius: 50%;
              text-align: center;
              line-height: 27px;
              &:hover,&.active {
                color: #1ba39c;
                background: #ffffff;
              }
            }
            .language-link {
              display: inline-block;
              width: 27px;
              height: 27px;
              background: #157a75;
              color: #7ed0cc;

              font-size: 8px;
              font-weight: 900;
              line-height: 18px;
              text-transform: uppercase;
              border-radius: 50%;
              text-align: center;
              line-height: 27px;
              text-decoration: none;
            }
          }
        }
      }
      .search {
        padding-top: 7px;
        #search-form, #views-exposed-form-search-page {
          position: relative;
          input[type="text"] {
            border: 1px solid #8dd1ce;
            width: 254px;
            height: 30px;
            background: none;
            border-radius: 0px;
            color: white;

            font-size: 13px;
            font-weight: 400;
            padding-left: 20px;
            @include placeholder {
                color: white;
            }
          }
          input[type="submit"] {
            margin-top: 0px;
            width: 20px;
            height: 21px;
            display: block;
            position: absolute;
            right: 21px;
            top: 5px;
            text-indent: -9999px;
            background: url('../images/search_ico.png');
            border-radius: 0px;
            border: 0px;
            padding: 0px;
          }
        }
      }
    }
    .region-menu-top {
      width: 450px;
      margin-right: 27px;
      .block__content {
        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          li {
            display: inline-block;
            float: left;
            a {
              width: 222px;
              height: 32px;
              background: #157a75;
              color: #7ed0cc;

              font-size: 11px;
              font-weight: 400;
              line-height: 20px;
              text-transform: uppercase;
              text-align: center;
              line-height: 32px;
              margin-left: 2px;
              margin-bottom: 2px;
              letter-spacing: 1px;
              padding: 0px;
              &:hover,&.active {
                background: #05605c;
                color: white;
                padding: 0px;
              }
            }
          }
        }
      }
    }
    .bottom_part_wrap {
      padding-top: 13px;
      clear: both;
      position: relative;
      .menu_button {
        float: right;
          padding: 8px;
          position: absolute;
          right: -8px;
          top: 7px;
          padding-bottom: 10px;
        span {
          width: 29px;
          height: 4px;
          background: white;
          margin: 4px 0px;
          display: block;
        }
      }
      #main-menu {
        float: left;
        margin: 0px;
        padding: 0px;
        display: flex;
        max-width: 790px;
        width: 100%;
        justify-content:space-between;
        li {
          display: inline-block;
          background: none;
          padding: 5px 0px;
          word-spacing: 1px;
          /*padding-right: 49px;*/
          width: 100%;
          &.last  {
            padding-right: 0px;
          }
          a {
            color: white;

            font-size: 13px;
            /*font-weight: 900;*/
            line-height: 18px;
            text-transform: uppercase;
            padding: 0px;
            background: none;
          }
        }
      }
    }
  }
}

//max-width: 1199px
@media (max-width: $screen-md-max) {
  #header {
    > .container {
      position: relative;
    }
    .logo_wrap {
      display: inline-block;
      float: none;
      text-align: center;
      width: 100%;
      .logo {
        position: relative;
        z-index: 10;
      }
      #logo {
        display: inline-block;
        height: auto;
        width: 75px;
      }
    }
    .slogan_wrap {
      display: inline-block;
      float: none;
      padding: 0;
      width: 100%;
      .block__content {
        text-align: center;
        p {
          br:nth-child(1),
          br:nth-child(2),
          br:nth-child(4),
          br:nth-child(5),
          br:nth-child(7) {
            display: none;
          }
        }
      }
    }
    .right_part_wrap {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      .language_find {
        .search {
          display: none;
        }
      }
      .top_part_wrap {
        display: inline-block;
        width: 100%;
        .language_find {
          float: left;
        }
      }
      .bottom_part_wrap {
        bottom: -174px;
        padding: 0;
        position: absolute;
        width: 100%;
        #main-menu {
          flex-wrap: wrap;
          li {
            margin-top: 0;
            margin-bottom: 10px;
            padding: 0;
            a {
              background-image: linear-gradient(to top, #05605c 0%, #1ba39c 100%);
              border-radius: 3px;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), inset 0 0 0 rgba(255, 255, 255, 0.25);
              height: 56px;
              line-height: 56px;
              color: #fff;
              font-size: 13px;
              font-weight: 400;
              text-align: center;
              &.active,
              &:hover {
                background-image: linear-gradient(to top, #05605c 0%, #157a75 100%);
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), inset 0 0 0 rgba(255, 255, 255, 0.25);
              }
            }
          }
        }
      }
    }
    .region-social-top {
      display: none;
    }
  }
  #block-menu-menu-menu-in-header {
    .block__content {
      ul.menu {
        li:nth-child(2),
        li:nth-child(4) {
          display: none;
        }
        li {
          a {
            background: #157a75;
            border-radius: 16px;
            color: #fff;
            font-size: 11px;
            font-weight: 400;
            height: 32px;
            width: 222px;
            &.active,
            &:hover {
              background: #fff;
              color: #157a75;
            }
          }
        }
      }
    }
  }
}

//min-width: 768px and max-width: 1199px
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
  #header {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.22);
    height: 169px;
    margin-bottom: 164px;
    > .container {
      height: 152px;
    }
    .region-slogan {
      margin-top: 15px;
    }
    .right_part_wrap {
      height: 152px;
      .bottom_part_wrap {
        #main-menu {
          li {
            margin-right: 10px;
            width: 243px;
            &:nth-child(3n+3) {
              margin-right: 0;
            }
          }
        }
        .menu_button {
          top: -197px;
        }
      }
      .region-menu-top {
        float: left;
        margin-left: 20px;
        width: auto;
        .block__content {
          ul {
            li {
              &:nth-child(3) {
                margin-bottom: 4px;
                margin-left: 150px;
              }
            }
          }
        }
      }
    }
  }
}

//max-width: 767px
@media (max-width: $screen-xs-max) {
  #header {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.22);
    height: 243px;
    margin-bottom: 210px;
    > .container {
      height: 226px;
    }
    .region-slogan {
      margin-top: 15px;
    }
    .right_part_wrap {
      height: 226px;
      .bottom_part_wrap {
        bottom: -230px;
        #main-menu {
          li {
            margin-right: 10px;
            width: calc(50% - 5px);
            &:nth-child(2n+2) {
              margin-right: 0;
            }
          }
        }
        .menu_button {
          top: -257px;
        }
      }
      .region-menu-top {
        float: none;
        margin-top: 140px;
        width: 100%;
        .block__content {
          ul {
            li {
              text-align: center;
              width: 100%;
              a {
                display: inline-block;
                width: 222px;
              }
            }
          }
        }
      }
    }
  }
}



